import React from 'react';
import { Icon } from 'src/components/Icons';
import { CloseBtn } from './CloseButton.styles';

interface ICloseButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'red' | 'darkBlue';
}

const CloseButton = (props: ICloseButton) => {
  return (
    <CloseBtn {...props} data-testid="close-btn">
      <Icon type="delete" />
    </CloseBtn>
  );
};

export default CloseButton;
