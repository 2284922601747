import { Grid } from '@material-ui/core';
import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Card } from '../../Card';
import { GridStyled } from 'src/styles/layout';
import Spinner from '../Spinner';

interface ILoadingCard extends WithNamespaces {
  title?: string;
}

const LoadingCard: StatelessComponent<ILoadingCard> = props => {
  const { t, title = '' } = props;

  return (
    <Card title={t(title)}>
      <GridStyled container={true} justify="center" padding={20}>
        <Grid item={true}>
          <Spinner />
        </Grid>
      </GridStyled>
    </Card>
  );
};

export default withNamespaces()(LoadingCard);
