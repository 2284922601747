import React, { FC } from 'react';
import { ISigningCaseListItem } from 'src/models';
import {
  isSigningCaseReadyToSign,
  isSigningCaseRejected,
  isSigningCaseSigned,
  isSigningCaseWaiting,
} from 'src/utils/SigningCase';
import { IconStyled } from './styles';

export interface IAgreementStatusIcon
  extends React.HTMLAttributes<HTMLElement> {
  status: ISigningCaseListItem['status'];
}

const AgreementStatusIcon: FC<IAgreementStatusIcon> = props => {
  const { status, children, ...rest } = props;

  return isSigningCaseReadyToSign(status) ? (
    <IconStyled type="warning" {...rest} />
  ) : isSigningCaseWaiting(status) ? (
    <IconStyled type="waiting" {...rest} />
  ) : isSigningCaseSigned(status) ? (
    <IconStyled type="ok" {...rest} />
  ) : isSigningCaseRejected(status) ? (
    <IconStyled type="delete" {...rest} />
  ) : null;
};

export default AgreementStatusIcon;
