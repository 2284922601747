import React, { FC, ComponentProps } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { DialogModal } from './DialogModal';

type Props = ComponentProps<typeof DialogModal> & WithNamespaces;

const DialogRejectModal: FC<Props> = props => {
  const { t } = props;

  const defaultTitle = t('Confirmation');
  const message: string = t(
    'IfYouRejectTheAgreementItWillBeDiscardedAndStoredInTheArchive'
  );
  const warning: string = t('AreYouSureYouWantToRejectTheAgreement');

  const { onCancel, onConfirm, title = defaultTitle } = props;
  const dialogProps = {
    title,
    message,
    warning,
    onCancel,
    onConfirm,
  };

  return <DialogModal {...dialogProps} data-testid={props['data-testid']} />;
};

const ExtendedComponent = withNamespaces()(DialogRejectModal);
export { ExtendedComponent as DialogRejectModal };
