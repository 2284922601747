import React from 'react';
import {
  AgreementStatusIcon,
  AgreementStatusText,
  IAgreementStatusIcon,
} from 'src/components/PortalList/PortalAgreementStatus';
import { StatusSubtitle } from './styles';

export const SigningCaseStatus = ({
  color,
  status,
  className,
}: IAgreementStatusIcon) => (
  <span className={className || ''}>
    <AgreementStatusIcon status={status} color={color} />
    <StatusSubtitle>
      <AgreementStatusText status={status} />
    </StatusSubtitle>
  </span>
);
