import React, { FC } from 'react';
import GenericNotification, {
  IGenericNotification,
} from '../GenericNotification';
import { Icon } from 'src/components/Icons';

type TErrorNotification = Pick<
  IGenericNotification,
  Exclude<keyof IGenericNotification, 'type'>
>;

const ErrorNotification: FC<TErrorNotification> = props => {
  const { text, ...notificationProps } = props;

  return (
    <GenericNotification
      type={'Error'}
      Icon={<Icon color="white" type="delete" />}
      text={text}
      {...notificationProps}
    />
  );
};

export default ErrorNotification;
