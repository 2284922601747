import React, { FC } from 'react';
import GenericNotification, {
  IGenericNotification,
} from '../GenericNotification';
import { Icon } from 'src/components/Icons';

type Notification = Pick<
  IGenericNotification,
  Exclude<keyof IGenericNotification, 'type'>
>;

const SuccessNotification: FC<Notification> = props => {
  const { ...notificationProps } = props;

  return (
    <GenericNotification
      type={'Success'}
      Icon={<Icon type="ok" color="white" />}
      {...notificationProps}
    />
  );
};

export default SuccessNotification;
