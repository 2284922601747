import { createGlobalStyle } from 'styled-components';

export const CookieConcentStyle = createGlobalStyle`
  label {
    span:first-child {
      padding: 5px;
    }

    span:last-child {
      font-size: 13px;
      line-height: 1.8;
      color: #7F9BAA;
      cursor: pointer;
    }
  }
`;
