import styled from 'styled-components';
import { Title } from '../Title';

export const CardTitle = styled(Title)`
  text-align: left;
  padding: 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  font-family: 'DanskeHuman';
  padding: 1em 22px 1.2em;
  width: 100%;
`;

export const TitleContainer = styled.div`
  width: 70%;
  text-align: left;
`;

export const StatusContainer = styled.div`
  vertical-align: top;
  text-align: right;
  flex-grow: 1;
`;
