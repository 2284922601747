import Grid from '@material-ui/core/Grid';
import React, { ComponentProps, StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import {
  Body,
  Button,
  Header,
  Menu,
  ModalContainer,
  Title,
} from '../components';

type TBodyProps = ComponentProps<typeof Body>;
interface InfoType extends WithNamespaces {
  onClose?: () => void;
  title: string;
  bodyProps?: TBodyProps;
}

const Info: StatelessComponent<InfoType> = props => {
  const { t, onClose, title } = props;
  const bodyPropsDefault: TBodyProps = {
    textAllign: 'justify',
  };
  const bodyProps: TBodyProps = {
    ...bodyPropsDefault,
    ...props.bodyProps,
  };

  return (
    <ModalContainer maxWidth={560}>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Header textAlign="left">
            <Title>{title}</Title>
          </Header>
        </Grid>
        <Grid item={true} xs={12}>
          <Body {...bodyProps} />
        </Grid>
        <Grid item={true} xs={12}>
          <Menu>
            <Button onClick={onClose}>{t('Close')}</Button>
          </Menu>
        </Grid>
      </Grid>
    </ModalContainer>
  );
};

export default withNamespaces()(Info);
