import React, { FC } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import ArrowUpWhite from 'src/assets/images/icons/arrow-link-up-collapse-small_white.svg';

const ExpandLess: FC<WithNamespaces> = ({ t }) => {
  return <img src={ArrowUpWhite} alt={t('ArrowUpAltText')} />;
};

const ExtendedComponent = withNamespaces()(ExpandLess);
export { ExtendedComponent as ExpandLess };
