import React, { StatelessComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { ITimeframeMenu, TimeframeClickType } from './models';
import { TimeframeButton } from './styles';

const Select: StatelessComponent<ITimeframeMenu> = props => {
  const { timeframe, onClick, t } = props;
  const today = 'Today';
  const month = 'Month';
  const month6 = 'Months6';
  const year = 'Year';

  const onTimeframeClick: TimeframeClickType = e =>
    onClick(e.currentTarget.value);

  return (
    <div role="menu">
      <TimeframeButton
        type="button"
        role="menuitem"
        isSelected={today === timeframe}
        onClick={onTimeframeClick}
        value={today}
      >
        {t('Now')}
      </TimeframeButton>
      <TimeframeButton
        type="button"
        role="menuitem"
        isSelected={month === timeframe}
        onClick={onTimeframeClick}
        value={month}
      >
        {t('LastMonth')}
      </TimeframeButton>
      <TimeframeButton
        type="button"
        role="menuitem"
        isSelected={month6 === timeframe}
        onClick={onTimeframeClick}
        value={month6}
      >
        {t('Last6Months')}
      </TimeframeButton>
      <TimeframeButton
        type="button"
        role="menuitem"
        isSelected={year === timeframe}
        onClick={onTimeframeClick}
        value={year}
      >
        {t('Last12Months')}
      </TimeframeButton>
    </div>
  );
};

const TimeframeSelect = withNamespaces()(Select);
export { TimeframeSelect };
