import React, { FC, useEffect, useRef } from 'react';
import { Background } from '../../../Background';
import { Modal } from './ModalContainer.styles';
import { IModal } from './models';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const ModalContainer: FC<IModal> = ({ children, ...rest }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const modalElement = modalRef.current;
    disableBodyScroll(modalElement);

    return () => enableBodyScroll(modalElement);
  }, []);

  return (
    <Background color="dark">
      <Modal ref={modalRef} {...rest}>
        {children}
      </Modal>
    </Background>
  );
};
