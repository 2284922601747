import { normalize } from 'normalizr';
import { ApiTypes, ISigningCase } from 'src/models';
import { TSigningCaseDetailsRoute } from 'src/models/RouteProps';
import { action, ActionType } from 'typesafe-actions';
import { IMarkDocumentSuccessProps } from './models';
import { Entity } from './services';
import ActionTypes from './types';

const Actions = {
  documentMarkRead: (props: ApiTypes.IMarkDocumentProps) =>
    action(ActionTypes.MARK_DOCUMENT_READ, props),
  documentMarkReadSuccess: (props: IMarkDocumentSuccessProps) =>
    action(ActionTypes.MARK_DOCUMENT_READ_SUCCESS, props),
  fetchSigningCase: (props: TSigningCaseDetailsRoute) =>
    action(ActionTypes.FETCH_SIGNING_CASE, props),
  fetchSigningCaseFailure: () => action(ActionTypes.FETCH_SIGNING_CASE_FAILURE),
  fetchSigningCaseRequest: () => action(ActionTypes.FETCH_SIGNING_CASE_REQUEST),
  fetchSigningCaseSuccess: (props: ISigningCase) =>
    action(
      ActionTypes.FETCH_SIGNING_CASE_SUCCESS,
      normalize(props, Entity.signingCase)
    ),
  resetOpenedSigningCase: () => action(ActionTypes.RESET_OPENED_SIGNING_CASE),
};

export type TActions = ActionType<typeof Actions>;
export default Actions;
