import { TActions } from './actions';
import { IState } from './models';
import ActionTypes from './types';

const initialState: IState = {
  isLoading: false,
};

const details = (state: IState, action: TActions) => {
  if (action.type === ActionTypes.FETCH_SIGNING_CASE_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  } else if (action.type === ActionTypes.FETCH_SIGNING_CASE_SUCCESS) {
    return {
      data: action.payload.entities,
      isLoading: false,
    };
  } else if (action.type === ActionTypes.FETCH_SIGNING_CASE_FAILURE) {
    return {
      ...state,
      isLoading: false,
    };
  } else if (action.type === ActionTypes.MARK_DOCUMENT_READ_SUCCESS) {
    const { documentId } = action.payload;
    const data = getData(state);
    const documents = getDocuments(state);
    const doc = getDocument(state, documentId);

    if (data && documents && documents[doc.id]) {
      return {
        ...state,
        data: {
          ...data,
          documents: markDocumentRead(documents, doc.id),
        },
      };
    }
    return initialState;
  } else if (action.type === ActionTypes.RESET_OPENED_SIGNING_CASE) {
    return initialState;
  }

  return state || initialState;
};

const markDocumentRead = (documents: any[], id: string) => ({
  ...documents,
  [id]: {
    ...documents[id],
    isRead: true,
  },
});

const doesDocumentsExist = (state: IState) =>
  state.data && state.data.documents;

const getData = (state: IState) => state.data;

const getDocuments = (state: IState) => {
  if (!doesDocumentsExist(state)) {
    return null;
  }

  return state.data!.documents;
};

const getDocument = (state: IState, documentId: string) => {
  const documents = getDocuments(state) || {};
  return documents[documentId];
};
export default details;
