import { t } from 'i18next';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as api from 'src/state/api';
import { notificationsManager } from 'src/state/Notifications';
import {
  Actions as SigningRulesAndSignatoriesActions,
  utils as SigningRulesAndSignatoriesUtils,
} from 'src/state/SigningRulesAndSignatories';
import SigningCaseActions from './actions';
import ActionTypes from './types';

type TfetchContractingParty = ReturnType<
  typeof SigningCaseActions['fetchSigningCase']
>;
function* fetchSigningCase(status: TfetchContractingParty) {
  try {
    yield all([
      put(SigningCaseActions.fetchSigningCaseRequest()),
      put(
        SigningRulesAndSignatoriesActions.fetchSigningRulesAndSignatoriesRequest()
      ),
    ]);

    const [signingCase, signingRulesAndSignatories] = yield all([
      call(api.getSigningCase, status.payload.caseId, status.payload.partyId),
      call(
        api.getSigningRulesAndSignatories,
        status.payload.caseId,
        status.payload.partyId
      ),
    ]);

    const translatedRulesAndSignatories = SigningRulesAndSignatoriesUtils.translateSigningRulesAndSignatories(
      signingRulesAndSignatories.data,
      t
    );

    yield put(SigningCaseActions.fetchSigningCaseSuccess(signingCase.data));
    yield put(
      SigningRulesAndSignatoriesActions.fetchSigningRulesAndSignatoriesSuccess(
        translatedRulesAndSignatories
      )
    );
  } catch (e) {
    yield put(SigningCaseActions.fetchSigningCaseFailure());
    yield put(
      SigningRulesAndSignatoriesActions.fetchSigningRulesAndSignatoriesFailure()
    );
    yield put(notificationsManager.addNotification(e));
  }
}

type MarkReadProps = ReturnType<typeof SigningCaseActions['documentMarkRead']>;
function* markDocumentRead(props: MarkReadProps) {
  try {
    yield call(api.markRead, props.payload);
    yield put(
      SigningCaseActions.documentMarkReadSuccess({
        documentId: props.payload.documentId,
      })
    );
  } catch (e) {
    return;
  }
}

export function* watchFetchSigningCase() {
  yield takeEvery(ActionTypes.FETCH_SIGNING_CASE, fetchSigningCase);
}

export function* watchMarkDocumentRead() {
  yield takeLatest(ActionTypes.MARK_DOCUMENT_READ, markDocumentRead);
}
