import React, { StatelessComponent } from 'react';
import { Card, CardBorderTop } from '../../Card';
import { SigningDocumentCover } from '../../Cover';

export const GenericFeedback: StatelessComponent = ({ children }) => {
  return (
    <>
      <SigningDocumentCover />
      <Card popout={true} showNotifications={false}>
        <CardBorderTop />
        {children}
      </Card>
    </>
  );
};
