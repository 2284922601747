import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { PortalButton } from 'src/components/Buttons';
import { PortalMenuButton } from 'src/components/Menu';

interface Props extends WithNamespaces {
  className?: string;
  canSign: boolean;
  onReject: () => void;
  onSign: () => void;
}

const SignMenu: FC<Props> = props => {
  const { canSign, onReject, onSign, t } = props;

  return (
    <Grid container={true} justify="flex-end" spacing={8}>
      <Grid item={true} xs={12} md="auto">
        <PortalMenuButton
          onClick={onReject}
          fullWidth={true}
          tabIndex={0}
          data-testid="reject-btn"
          colorType="error"
        >
          {t('Reject')}
        </PortalMenuButton>
      </Grid>
      <Grid item={true} xs={12} md="auto">
        <PortalButton
          disabled={!canSign}
          onClick={onSign}
          fullWidth={true}
          tabIndex={0}
          data-testid="sign-btn"
        >
          {t('Sign')}
        </PortalButton>
      </Grid>
    </Grid>
  );
};

export default withNamespaces()(SignMenu);
