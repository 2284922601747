import React, { MouseEvent, StatelessComponent } from 'react';
import { DeadlineDate } from 'src/components/PortalList/PortalDeadlineDate';
import { ISigningCaseListItem } from 'src/models';
import {
  darkBlue,
  darkBlueLight,
  darkGreen,
  darkRed,
  darkYellow,
} from 'src/styles/colors';
import {
  isSigningCaseReadyToSign,
  isSigningCaseRejected,
  isSigningCaseSigned,
  isSigningCaseWaiting,
} from 'src/utils/SigningCase';
import {
  AgreementDeadlineMobile,
  TableCellMobile,
  TableCellTitle,
  TableCellBig,
  TableCellLast,
} from 'src/components';
import { Row, Title, AgreementStatus, AgreementStatusMobile } from './styles';

type TableRowEventType = MouseEvent<HTMLTableRowElement>;

interface Props {
  document: ISigningCaseListItem;
  onClick?: (e: TableRowEventType, document: ISigningCaseListItem) => void;
}

export const SigningCasesListItem: StatelessComponent<Props> = props => {
  const { document, onClick } = props;
  const color = getAgreementColor(
    document.status,
    document.contractingPartyType
  );

  const onSigningCaseClick = (e: TableRowEventType) => {
    if (onClick) {
      onClick(e, document);
    }
  };

  return (
    <Row
      contractingPartyType={document.contractingPartyType}
      documentStatus={document.status}
      tabIndex={0}
      onClick={onSigningCaseClick}
    >
      <TableCellTitle color={color}>
        <Title title={document.name} subtitle={document.contractingPartyName} />
      </TableCellTitle>
      <TableCellBig>
        <DeadlineDate date={document.date} />
      </TableCellBig>
      <TableCellLast align="right" hide={true}>
        <AgreementStatus status={document.status} color={color} />
      </TableCellLast>
      <TableCellMobile color={color}>
        <AgreementDeadlineMobile date={document.date} />
        <AgreementStatusMobile status={document.status} color={color} />
      </TableCellMobile>
    </Row>
  );
};

const getAgreementColor = (
  signingCaseStatus: ISigningCaseListItem['status'],
  contractingPartyType: ISigningCaseListItem['contractingPartyType']
): string => {
  if (
    isSigningCaseReadyToSign(signingCaseStatus) &&
    contractingPartyType === 'Person'
  ) {
    return darkBlueLight;
  } else if (isSigningCaseWaiting(signingCaseStatus)) {
    return darkYellow;
  } else if (isSigningCaseRejected(signingCaseStatus)) {
    return darkRed;
  } else if (isSigningCaseSigned(signingCaseStatus)) {
    return darkGreen;
  }

  return darkBlue;
};
