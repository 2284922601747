import React, { StatelessComponent } from 'react';

interface IValidityDate {
  title: string;
  content: string;
}

const ValidityDate: StatelessComponent<IValidityDate> = props => {
  return (
    <div>
      <span>{props.title}: </span>
      <strong>{props.content}</strong>
    </div>
  );
};

export default ValidityDate;
