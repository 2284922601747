import { CloseButton as CloseBtn } from 'src/components/Buttons';
import { TNotificationType } from 'src/models';
import { darkBlue, darkGreen, darkRed, darkYellow } from 'src/styles/colors';
import styled from 'styled-components';

interface INotificationContainer {
  type: TNotificationType;
}

const getNotificationBackground = (type: TNotificationType) => {
  return type === 'Error'
    ? darkRed
    : type === 'Warning'
    ? darkYellow
    : type === 'Success'
    ? darkGreen
    : 'white';
};

export const NotificationContainer = styled.div<INotificationContainer>`
  display: flex;
  width: 100%;
  background-color: ${props => getNotificationBackground(props.type)};
`;

export const InlineBlock = styled.span`
  display: inline-block;
`;

export const IconContainer = styled(InlineBlock)`
  width: auto;
  font-size: 3em;
  padding: 15px 20px;
`;

export const TextContainer = styled(InlineBlock)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 20px 0;
`;

export const ButtonContainer = styled(InlineBlock)`
  width: auto;
  text-align: right;
  padding: 15px 20px;
`;

export const CloseButton = styled(CloseBtn)`
  color: rgba(0, 55, 85, 0.7);
  font-size: 20px;

  &:hover {
    cursor: pointer;
    color: inherit;
  }
`;

interface INotificationTextProps {
  type?: 'default' | 'warning';
}

export const NotificationText = styled.span<INotificationTextProps>`
  color: ${props => (props.type === 'warning' ? darkBlue : 'white')};
  font-family: 'DanskeHuman';
  line-height: 1.4em;
`;

export const NotificationWarningText = styled(NotificationText)`
  color: ${darkBlue};
`;
