import { Grid } from '@material-ui/core';
import { t } from 'i18next';
import React, { StatelessComponent } from 'react';
import { PortalLink } from 'src/components/Buttons';
import { MessageScreen } from 'src/components/Feedback';

interface Props {
  caseId: string;
  contractingPartyId: string;
}

export const SuccessScreen: StatelessComponent<Props> = props => {
  const { caseId, contractingPartyId } = props;

  return (
    <MessageScreen
      title={t('MandateCreateSuccess')}
      info={t('MandateProcessed')}
      menu={
        <Grid container={true} item={true} justify="center">
          <PortalLink to={`/Case/${caseId}/Party/${contractingPartyId}`}>
            {t('MandateGoToSigningCase')}
          </PortalLink>
        </Grid>
      }
    />
  );
};
