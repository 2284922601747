export const makeTransparent = (color: string) => `${color}24`;

// Primary colors
export const darkBlueLight = '#16A7F3';
export const darkBlue = '#002337';
export const backgroundColor = '#FBFDFE';
// Secondary colors
export const lightBlue = '#E3EBEF';
export const transparentBlue = '#FBFDFD';
export const darkGreen = '#20A878';
export const darkGreenLight = '#6EC8B6';
export const lightGreen = '#DBF4EF';
export const lightWhite = 'rgba(255,255,255,0.1)';
export const lightWhite2 = 'rgba(255,255,255,0.2)';
export const darkYellow = '#F0A56E';
export const darkRed = '#EC4E63';
export const grey = '#8596A0';
export const darkGrey = '#123245';
export const white = '#fff';
// RealKreditDenmark website colors
export const rdRed = '#cc0000';
export const rdBlack = '#333';

export const linearGradientGrey = `linear-gradient(
  to bottom, ${lightWhite} 0%, rgba(0,0,0,0.1) 100%)`;

/* https://css-tricks.com/converting-color-spaces-in-javascript/ */
const hexToHSL = hex => {
  // Convert hex to RGB first
  let r: any = 0,
    g: any = 0,
    b: any = 0;

  if (hex.length === 4) {
    r = '0x' + hex[1] + hex[1];
    g = '0x' + hex[2] + hex[2];
    b = '0x' + hex[3] + hex[3];
  } else if (hex.length === 7) {
    r = '0x' + hex[1] + hex[2];
    g = '0x' + hex[3] + hex[4];
    b = '0x' + hex[5] + hex[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
};

export const hexToHSLWithGradient = hex => {
  const { h, s, l } = hexToHSL(hex);

  return `linear-gradient(\
      to bottom,\
      hsla(${h}, ${s}%, ${l + 4}%, 1) 0%,\
      hsla(${h}, ${s - 2}%, ${l}%, 1) 100%\
    );`;
};

export const hexToHSLPressed = hex => {
  const { h, s, l } = hexToHSL(hex);

  return `hsla(${h}, ${s}%, ${hex === darkBlueLight ? l + 2 : l + 10}%, 1);`;
};
