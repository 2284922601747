import React, { FC, HTMLAttributes } from 'react';
import { ListContainer } from '../styles';
import { Title as TitleText } from './Title.styles';

export const Title: FC<HTMLAttributes<HTMLHeadingElement>> = props => {
  return (
    <ListContainer>
      <TitleText {...props} />
    </ListContainer>
  );
};
