import React, { StatelessComponent } from 'react';
import { InputProps } from 'reactstrap';
import { LabelBold } from './components';
import { InputStyled } from './GenericInput.styles';

export interface InputType {
  className?: string;
  labelText?: string;
  inputProps?: InputProps;
}

const GenericInput: StatelessComponent<InputType> = props => {
  const { children, inputProps = {}, labelText } = props;

  return (
    <>
      {labelText ? (
        <LabelBold htmlFor={inputProps.name}>{labelText}</LabelBold>
      ) : null}
      <InputStyled {...inputProps} id={inputProps.name}>
        {children}
      </InputStyled>
    </>
  );
};

export default GenericInput;
