import React, { FC } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { PortalButton } from 'src/components/Buttons';
import { Config } from 'src/state';

interface Props extends WithNamespaces {
  onConfirm: () => void;
  onCancel: () => void;
}

const Footer: FC<Props> = props => {
  const { onConfirm, onCancel, t } = props;
  const rejectButtonType = Config.isRealkreditDenmark()
    ? 'primary'
    : 'secondary';

  return (
    <Grid container wrap="wrap" spacing={16}>
      <Grid item xs={12} sm={6}>
        <PortalButton onClick={onConfirm} fullWidth>
          {t('SessionContinue')}
        </PortalButton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PortalButton onClick={onCancel} colorType={rejectButtonType} fullWidth>
          {t('LogOff')}
        </PortalButton>
      </Grid>
    </Grid>
  );
};

const ExtentedComponent = withNamespaces()(Footer);
export { ExtentedComponent as Footer };
