import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface IDocumentsListInfoText extends WithNamespaces {
  className?: string;
}

const DocumentsListInfoText: StatelessComponent<
  IDocumentsListInfoText
> = props => {
  const { className, t } = props;

  return (
    <span className={className}>
      {t('YouMustReviewAllDocumentsBeforeSigning')}
    </span>
  );
};

export default withNamespaces()(DocumentsListInfoText);
