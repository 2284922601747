import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { TableCellBig } from 'src/components';
import {
  LineBreakText,
  TableCell,
  TableRow,
} from './MandatesTableLineBreak.styles';

const MandatesTableLineBreak: StatelessComponent<WithNamespaces> = ({ t }) => (
  <TableRow>
    <TableCell>
      <LineBreakText>{t('ExpiredAndRevokedMandates')}</LineBreakText>
    </TableCell>
    <TableCellBig />
    <TableCellBig />
  </TableRow>
);

export default withNamespaces()(MandatesTableLineBreak);
