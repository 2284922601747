import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import {
  INewSignatory,
  TRemoveCallback,
  TRemoveSignatory,
  TUpdateSignatory,
  TUpdateSignatoryCallback,
} from 'src/pages/Mandates/models';
import SignatoryListRow from './components/SignatoryListRow';
import { EmptyListText } from './SignatoriesList.styles';

interface ISignatoriesList extends WithNamespaces {
  signatories?: INewSignatory[];
  removeSignatory: TRemoveCallback;
  updateSignatory: TUpdateSignatoryCallback;
  refreshSignatory: any;
}

const SignatoriesList: StatelessComponent<ISignatoriesList> = props => {
  const {
    t,
    signatories = [],
    removeSignatory,
    updateSignatory,
    refreshSignatory,
  } = props;

  return (
    <>
      {signatories.length ? (
        signatories.map((signatory, index) => (
          <SignatoryListRow
            key={index}
            signatory={signatory}
            removeSignatory={onRemoveSignatory(index, removeSignatory)}
            updateSignatory={onUpdateSignatory(index, updateSignatory)}
            refreshSignatory={refreshSignatory}
          />
        ))
      ) : (
        <EmptyListText>{t('MandateNoSignatoriesCurrentlySet')}</EmptyListText>
      )}
    </>
  );
};

const onRemoveSignatory: TRemoveSignatory = (id, callback) => () => {
  callback(id);
};

const onUpdateSignatory: TUpdateSignatory = (id, callback) => signatory => {
  callback({ id, signatory });
};

export default withNamespaces()(SignatoriesList);
