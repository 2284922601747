import { Icon } from 'src/components/Icons';
import styled from 'styled-components';

export const IconStyled = styled(Icon)`
  font-size: 50px;
`;

export const MessageContainer = styled.div`
  text-align: left;
`;

export const Time = styled.p`
  font-size: 24px;
`;
