import React, { StatelessComponent } from 'react';
import { IOtherContractingPartiesList } from './ISigneesList';
import SigneeTableRow from './PortalSigneeRow';
import { formatDate } from './utils';

export const OtherContractingPartiesListBody: StatelessComponent<
  IOtherContractingPartiesList
> = props => {
  const { contractingParties } = props;

  return (
    <>
      {contractingParties.map((contractingParty, index) => {
        const {
          actionDateTime,
          actionRole,
        } = contractingParty.signatures.reduce(
          (acc, signatory) => {
            return acc.actionDateTime < signatory.actionDateTime
              ? signatory
              : acc;
          },
          { actionDateTime: '' } as any
        );

        return (
          <SigneeTableRow
            key={index}
            actionDateTime={actionDateTime && formatDate(actionDateTime)}
            actionRole={actionRole && actionRole}
            companyRoles={contractingParty.type}
            fullName={contractingParty.name}
            data-testid="other-contracting-party"
          />
        );
      })}
    </>
  );
};
