import React, { StatelessComponent } from 'react';
import CompanyFormGroup from '../CompanyFormGroup';
import DetailsFormGroup from '../DetailsFormGroup';
import ProductAreaFormGroup from '../ProductAreaFormGroup';
import SignatoriesFormGroup from '../SignatoriesFormGroup';
import { FormContainer } from './FormBody.styles';

interface IFormBody {
  step: number;
}

const FormBody: StatelessComponent<IFormBody> = props => {
  const { step } = props;

  return (
    <FormContainer>
      {step === 0 ? (
        <CompanyFormGroup />
      ) : step === 1 ? (
        <SignatoriesFormGroup />
      ) : step === 2 ? (
        <ProductAreaFormGroup />
      ) : step === 3 ? (
        <DetailsFormGroup />
      ) : null}
    </FormContainer>
  );
};

export default FormBody;
