import { t } from 'i18next';
import React, { FC, HTMLAttributes } from 'react';
import { darkRed } from 'src/styles/colors';
import { Container, TagText } from './Tag.styles';

interface Props {
  text: string;
}

export const ErrorTag: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
  className,
  text,
}) => (
  <Container className={className} color={darkRed}>
    <TagText uppercase={true}>{t(text)}</TagText>
  </Container>
);
