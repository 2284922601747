import React, { StatelessComponent } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { Table, TableHead, TableHeaderCell } from 'src/components';
import { MandateLimits } from 'src/models';
import { SigningRuleTabelRow } from './components';

interface ISigningRules extends WithNamespaces {
  limits: MandateLimits;
}

const SigningRules: StatelessComponent<ISigningRules> = props => {
  const { t, limits } = props;

  return (
    <Table>
      <TableHead>
        <tr>
          <TableHeaderCell>{t('MandateProductArea')}</TableHeaderCell>
        </tr>
      </TableHead>
      <tbody>
        {limits.map((limit, index) => (
          <SigningRuleTabelRow key={index} limit={limit} />
        ))}
      </tbody>
    </Table>
  );
};

export default withNamespaces()(SigningRules);
