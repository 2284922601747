import styled from 'styled-components';

export const Content = styled.div`
  padding: 15px;
  background-color: white;
`;

const borderRadius = '3px';

export const Header = styled(Content)`
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
`;

export const Footer = styled(Content)`
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
`;
