import { TableCellFirst, TableCellLast } from 'src/components';
import { TableTitle } from 'src/components';
import { darkGreen, darkRed } from 'src/styles/colors';
import styled from 'styled-components';
import { ISignee } from './ISignee';

export const SigneeTitle = styled(TableTitle)`
  font-weight: 400;
  white-space: normal;

  & > span {
    white-space: normal;
  }
`;

const cellCursor = 'cursor: default;';
export const SigneeCell = styled(TableCellFirst)`
  ${cellCursor}
`;

export const SigneeCellLast = styled(TableCellLast)`
  ${cellCursor}
  text-align: right;
`;

interface ISigneeActionText {
  actionRole: ISignee['actionRole'];
}

const getSigneeActionColor = (props: ISigneeActionText) => {
  const { actionRole } = props;

  return actionRole === 'Signee'
    ? darkGreen
    : actionRole === 'Rejector'
    ? darkRed
    : 'black';
};

export const SigneeActionTitle = styled.div<ISigneeActionText>`
  display: flex;
  justify-content: flex-end;
  color: ${getSigneeActionColor};
`;

export const IconContainer = styled.span`
  font-size: 1.2em;
`;

export const SigneeActionText = styled.span`
  font-size: 0.7em;
  margin-right: 5px;
  align-self: center;
`;

export const SigneeActionDateContainer = styled.div`
  margin-top: 0.2em;
`;

export const SigneeActionDate = styled.span`
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
`;
