import React, { StatelessComponent, HTMLAttributes } from 'react';
import { GenericTableRow } from 'src/components/PortalList/PortalList.styles';
import { ISignatureResponse } from 'src/models';
import SigneeAction from './PortalSigneeAction';
import {
  SigneeCell,
  SigneeCellLast,
  SigneeTitle,
} from './PortalSigneesList.styles';

interface ISigneeRow extends HTMLAttributes<HTMLTableRowElement> {
  companyRoles: string;
  fullName: string;
  actionDateTime?: ISignatureResponse['actionDateTime'];
  actionRole?: ISignatureResponse['actionRole'];
}

const SigneeRow: StatelessComponent<ISigneeRow> = props => {
  const { actionDateTime, actionRole, companyRoles, fullName, ...rest } = props;

  return (
    <GenericTableRow {...rest}>
      <SigneeCell>
        <SigneeTitle title={fullName} subtitle={companyRoles} />
      </SigneeCell>
      <SigneeCellLast>
        {actionDateTime && actionRole ? (
          <SigneeAction
            actionRole={actionRole}
            actionDateTime={actionDateTime}
          />
        ) : null}
      </SigneeCellLast>
    </GenericTableRow>
  );
};

export default SigneeRow;
