import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import React, { StatelessComponent } from 'react';
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next';
import { TonCookieSelect } from 'src/pages/Mandates/models';
import { CookieConcentStyle } from './CookieConsent.styles';
import { Link } from 'src/components/Text';

interface ICookieConsent extends WithNamespaces {
  onClick: TonCookieSelect;
  value?: boolean;
}

const CookieConsent: StatelessComponent<ICookieConsent> = props => {
  const { t, onClick, value = false } = props;

  return (
    <FormGroup row={true}>
      <FormControlLabel
        label={
          <Trans
            i18nKey="SignatoryPersonalDataConsent"
            components={[
              <Link
                href={t('PrivacyNoticePrivateLink')}
                target="_blank"
                rel="noreferrer noopener"
                colorType="secondary"
              >
                Text
              </Link>,
              <Link
                href={t('PrivacyNoticeBusinessLink')}
                target="_blank"
                rel="noreferrer noopener"
                colorType="secondary"
              >
                Text
              </Link>,
            ]}
          />
        }
        control={
          <Checkbox id="CookieConsent" onClick={onClick} checked={value} />
        }
      />
      <CookieConcentStyle />
    </FormGroup>
  );
};

export default withNamespaces()(CookieConsent);
