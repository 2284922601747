import { Grid } from '@material-ui/core';
import { t } from 'i18next';
import React, { StatelessComponent } from 'react';
import { PortalLink } from 'src/components/Buttons';
import { MessageScreen } from 'src/components/Feedback';

export const ErrorScreen: StatelessComponent = () => {
  return (
    <MessageScreen
      title={t('MandateCreateFailed')}
      info={t('ErrorMandateProcessingFailed')}
      menu={
        <Grid container={true} item={true} justify="center">
          <PortalLink to="/Mandates">{t('MandateGoToMandatesList')}</PortalLink>
        </Grid>
      }
    />
  );
};
