import React, { StatelessComponent } from 'react';
import { CloseButton } from 'src/components/Buttons';
import {
  CloseBtnContainer,
  Header,
  TitleContainer,
} from './DialogModalHeader.styles';

interface IDialogModalHeader {
  title?: string;
  onClick: () => void;
}

const DialogModalHeader: StatelessComponent<IDialogModalHeader> = props => {
  const { title = 'Reject agreement', onClick } = props;

  return (
    <Header>
      <TitleContainer>{title}</TitleContainer>
      <CloseBtnContainer>
        <CloseButton onClick={onClick} color={'darkBlue'} />
      </CloseBtnContainer>
    </Header>
  );
};

export default DialogModalHeader;
