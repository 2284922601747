import { Grid } from '@material-ui/core';
import React, { ChangeEvent, Component } from 'react';
import { Input } from 'src/components/Input';
import { BlueSpinner } from 'src/components/Loaders';
import { ErrorTag } from 'src/components/Tag';
import { IconStyled } from 'src/pages/Mandates/components/Icon';
import {
  INewSignatory,
  TRemoveSignatory,
  TUpdateSignatory,
} from 'src/pages/Mandates/models';
import { isCustomerNotFoundError, isInternalServerErr } from 'src/state/Errors';
import SignatoryMenu from '../SignatoryMenu';
import { Col, GridFullName, GridPerson, Row } from './SignatoryListRow.styles';

interface ISignatoryListRow {
  signatory: INewSignatory;
  removeSignatory: ReturnType<TRemoveSignatory>;
  updateSignatory: ReturnType<TUpdateSignatory>;
  refreshSignatory: any;
}

const initialState = {
  disabled: true,
};

type State = Readonly<typeof initialState>;

class SignatoryListRow extends Component<ISignatoryListRow, State> {
  readonly state: State = initialState;

  public render() {
    const { signatory, removeSignatory } = this.props;
    const { disabled } = this.state;
    const hasError = signatory.error && signatory.error.length > 0;

    return (
      <Row
        container={true}
        border={true}
        hasError={hasError}
        alignItems={'center'}
      >
        {signatory.error ? <ErrorTag text={signatory.error} /> : null}
        <Col item={true} xs={12} lg={4}>
          <Grid container={true} item={true} alignItems="center" spacing={8}>
            <GridPerson item={true}>
              <IconStyled type="person" />
            </GridPerson>
            <GridFullName item={true}>
              <Input
                inputProps={{
                  disabled,
                  onChange: this.changeSignatory('fullName'),
                  value: signatory.fullName,
                }}
              />
            </GridFullName>
          </Grid>
        </Col>
        <Col item={true} xs={12} lg={4}>
          <Input
            inputProps={{
              disabled,
              onChange: this.changeSignatory('externalId'),
              value: signatory.externalId,
            }}
          />
        </Col>
        <Col container={true} item={true} justify="center" xs={12} lg={4}>
          {signatory.isLoading ? (
            <BlueSpinner size={35} />
          ) : (
            <SignatoryMenu
              editable={isCustomerNotFoundError(signatory.error)}
              needsRefresh={isInternalServerErr(signatory.error)}
              disabled={disabled}
              removeSignatory={removeSignatory}
              toggleEdit={this.toggleButtons}
              refresh={this.onRefresh}
            />
          )}
        </Col>
      </Row>
    );
  }
  private onRefresh = () => {
    this.props.refreshSignatory(this.props.signatory);

    if (!this.state.disabled) {
      this.toggleButtons();
    }
  };

  private toggleButtons = () => this.setState(toggleButtons);

  private changeSignatory = (prop: keyof INewSignatory) => (
    e: ChangeEvent<HTMLInputElement>
  ) =>
    this.props.updateSignatory({
      ...this.props.signatory,
      [prop]: e.target.value,
    });
}

const toggleButtons = (prevState: State): State => ({
  disabled: !prevState.disabled,
});

export default SignatoryListRow;
