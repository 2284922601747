import React, { StatelessComponent } from 'react';
import { Title, Subtitle } from './styles';

interface Props {
  title: string;
  subtitle: string;
  className?: string;
}

export const TableTitle: StatelessComponent<Props> = props => {
  const { title, subtitle, children, className } = props;
  return (
    <Title className={className}>
      {title}
      <Subtitle>{subtitle}</Subtitle>
      {children}
    </Title>
  );
};
