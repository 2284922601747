import React, { FC } from 'react';

import { Icon } from 'src/components/Icons';
import { HamburgerDropdown, HamburgerBadge } from './HamburgerMenu.styles';
import { ITheme } from './IHamburgerMenu';

interface Props {
  awaitingSigningCasesCount: number;
  isOpen: boolean;
}

const PortalHamburgerButton: FC<ITheme & Props> = ({
  awaitingSigningCasesCount,
  isOpen,
  ...rest
}) => (
  <HamburgerDropdown {...rest}>
    <HamburgerBadge
      max={9}
      color="primary"
      isOpen={isOpen}
      badgeContent={awaitingSigningCasesCount}
    >
      <Icon type="menu" />
    </HamburgerBadge>
  </HamburgerDropdown>
);

export default PortalHamburgerButton;
