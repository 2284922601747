import styled from 'styled-components';

export const FooterContainer = styled.footer`
  width: 100%;
  margin-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 16px;
`;

export const Text = styled.span`
  opacity: 0.7;
  font-size: 0.7em;
`;

export const TelNumber = styled.a`
  color: ${({ theme }) => theme.pallete.secondary.main};

  &:hover {
    color: ${({ theme }) => theme.pallete.action.hover};
  }
`;
