import { TextAlignProperty } from 'csstype';
import styled from 'styled-components';

interface IModalBody {
  textAllign?: TextAlignProperty;
}

export const ModalBody = styled.div<IModalBody>`
  padding: 15px;
  font-size: 1em;
  background-color: white;
  text-align: ${props => props.textAllign || 'unset'};
  word-break: break-word;
`;
