import React, { StatelessComponent } from 'react';
import { IconStyled } from 'src/pages/Mandates/components';
import { IconContainer } from './PersonIcon.styles';

const PersonIcon: StatelessComponent = () => {
  return (
    <IconContainer>
      <IconStyled type="person" />
    </IconContainer>
  );
};

export default PersonIcon;
