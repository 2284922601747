import styled from 'styled-components';

interface NameProps {
  isBold?: boolean;
}

export const DocumentName = styled.div<NameProps>`
  word-break: break-all;
  font-weight: ${props => (props.isBold ? 'bold' : '400')};
`;

export const DocumentNameSubtitle = styled.div`
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.7;
  font-size: 0.8em;
  margin-top: 0.2em;
  font-weight: 400;
  word-break: break-all;
`;
