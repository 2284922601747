import { flowRight } from 'lodash';
import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { ISignee } from './ISignee';
import SigneeActionIcon from './PortalSigneeActionIcon';
import {
  SigneeActionDate,
  SigneeActionDateContainer,
  SigneeActionText,
  SigneeActionTitle,
} from './PortalSigneesList.styles';

interface ISigneeAction extends WithNamespaces {
  actionDateTime: ISignee['actionDateTime'];
  actionRole: ISignee['actionRole'];
}

const SigneeAction: StatelessComponent<ISigneeAction> = props => {
  const { actionRole, actionDateTime, t } = props;

  return (
    <div>
      <SigneeActionTitle actionRole={actionRole}>
        <SigneeActionText>
          {flowRight(
            t,
            getActionText
          )(actionRole)}
        </SigneeActionText>
        <SigneeActionIcon actionRole={actionRole} />
      </SigneeActionTitle>
      <SigneeActionDateContainer>
        <SigneeActionDate>{actionDateTime}</SigneeActionDate>
      </SigneeActionDateContainer>
    </div>
  );
};

const getActionText = (actionRole: ISignee['actionRole']): string => {
  return actionRole === 'Signee'
    ? 'Signed'
    : actionRole === 'Rejector'
    ? 'Rejected'
    : '';
};

export default withNamespaces()(SigneeAction);
