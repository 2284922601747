import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { IOtherContractingPartiesList as IContractingParties } from './ISigneesList';
import { OtherContractingPartiesListBody } from './OtherContractingPartiesListBody';
import { List, Title } from './OtherContractingParties.styles';
import SigneesListFrame from './SigneesListFrame';
import { ISigningCaseStatus } from 'src/models';

interface IOtherContractingPartiesList
  extends IContractingParties,
    WithNamespaces {
  status?: keyof ISigningCaseStatus;
  showHeader?: boolean;
}

const OtherContractingPartiesList: StatelessComponent<
  IOtherContractingPartiesList
> = props => {
  const { t, showHeader, contractingParties, status = 'NotSigned' } = props;

  if (contractingParties && contractingParties.length) {
    return (
      <List status={status} data-testid="other-contracting-parties">
        <Title>{t('ContractingParties')}</Title>
        <SigneesListFrame
          render={renderSigneesListBody({ contractingParties })}
          showHeader={showHeader}
        />
      </List>
    );
  } else {
    return null;
  }
};

const renderSigneesListBody = (props: IContractingParties) => () => (
  <OtherContractingPartiesListBody {...props} />
);

export default withNamespaces()(OtherContractingPartiesList);
