import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { EmptyArchiveMessageContainer } from './styles';

const EmptyArchiveMessage: StatelessComponent<WithNamespaces> = props => {
  return (
    <EmptyArchiveMessageContainer data-testid={props['data-testid']}>
      <span>{props.t('YouHaveNoAgreementsInTheArchive')}</span>
    </EmptyArchiveMessageContainer>
  );
};

const ExtendedComponent = withNamespaces()(EmptyArchiveMessage);
export { ExtendedComponent as EmptyArchiveMessage };
