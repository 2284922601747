import React, { ComponentProps, StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { ButtonContainer } from './ReturnButton.styles';
import { PortalLink } from '../PortalButton';

interface IReturnButton extends WithNamespaces {
  linkProps: ComponentProps<typeof PortalLink>;
}

const ReturnButton: StatelessComponent<IReturnButton> = ({
  t,
  linkProps: { colorType = 'secondary', ...rest },
  children,
}) => {
  return (
    <ButtonContainer>
      <PortalLink colorType={colorType} {...rest}>
        {children || t('ReturnToAgreements')}
      </PortalLink>
    </ButtonContainer>
  );
};

export default withNamespaces()(ReturnButton);
