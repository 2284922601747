import { Grid } from '@material-ui/core';
import React, { StatelessComponent, SyntheticEvent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Input } from 'src/components/Input';

interface IRequiredSignatories extends WithNamespaces {
  requiredSignatories: number;
  onClick: (event: SyntheticEvent<HTMLInputElement>) => void;
  value: string;
}

const RequiredSignatoriesSelect: StatelessComponent<
  IRequiredSignatories
> = props => {
  const { t, requiredSignatories, onClick, value } = props;

  return (
    <Grid container={true} justify="center" spacing={16}>
      <Grid item={true}>
        <span>{t('MandateRequiredSignatories')}</span>
      </Grid>
      <Grid item={true}>
        <Input
          inputProps={{
            onChange: onClick,
            type: 'select',
            value,
          }}
        >
          {Array(requiredSignatories)
            .fill(0)
            .map((_, k) => (
              <option key={k}>{k + 1}</option>
            ))}
        </Input>
      </Grid>
    </Grid>
  );
};

export default withNamespaces()(RequiredSignatoriesSelect);
