import React, { ButtonHTMLAttributes, StatelessComponent } from 'react';
import { Icon } from 'src/components/Icons';
import { Circle } from './CircleButton.styles';

interface ICircleButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconType?: 'ok' | 'delete' | 'refresh' | 'pencil';
}

const CircleButton: StatelessComponent<ICircleButton> = props => {
  const { children, iconType, ...buttonProps } = props;

  return (
    <Circle {...buttonProps}>
      {iconType ? <Icon type={iconType} /> : null}
      {children}
    </Circle>
  );
};

export default CircleButton;
