import React, { StatelessComponent } from 'react';
import { AgreementStatusText } from 'src/components/PortalList/PortalAgreementStatus';
import { StatusIcon, StatusParagraph } from './ContractingPartyStatus.styles';
import { IContractingPartyStatus } from './IContractingPartyStatus';
import { getSigningCaseHeaderColor } from '../../services';
import {
  isSigningCaseReadyToSign,
  isSigningCaseRejected,
  isSigningCaseSigned,
  isSigningCaseWaiting,
} from 'src/utils/SigningCase';

const ContractingPartyStatus: StatelessComponent<
  IContractingPartyStatus
> = props => {
  const { status, className } = props;

  return (
    <div className={className}>
      <StatusIcon
        status={status}
        color={getSigningCaseHeaderColor(props.status)}
        style={getSigningCaseStyle(props.status)}
      />
      <StatusParagraph status={status}>
        <AgreementStatusText status={status} />
      </StatusParagraph>
    </div>
  );
};

export default ContractingPartyStatus;

const getSigningCaseStyle = status => {
  return isSigningCaseReadyToSign(status)
    ? { marginRight: '-13px' }
    : isSigningCaseWaiting(status)
    ? { marginRight: '-5px' }
    : isSigningCaseSigned(status)
    ? { marginRight: '-4px' }
    : isSigningCaseRejected(status)
    ? { marginRight: '-6px' }
    : {};
};
