import React, { FC } from 'react';
import { ModalContainer } from '../components';
import Header from './DialogModalHeader';
import Menu from './DialogModalMenu';
import { Content, Footer } from './styles';

interface Props {
  title?: string;
  footer?: JSX.Element;
  onConfirm?: () => void;
  onCancel: () => void;
}

export const Modal: FC<Props> = ({
  title,
  footer,
  onCancel,
  onConfirm,
  children,
}) => {
  return (
    <ModalContainer width={360}>
      <Header title={title} onClick={onCancel} />
      <Content>{children}</Content>
      <Footer>
        {footer ||
          (onConfirm && <Menu onConfirm={onConfirm} onCancel={onCancel} />)}
      </Footer>
    </ModalContainer>
  );
};
