import React, { StatelessComponent } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { Table } from 'reactstrap';
import { TableHead, TableHeaderCell } from 'src/components';

interface ISigneesList extends WithNamespaces {
  showHeader?: boolean;
  showRoleHeader?: boolean;
  render?: (props?: any) => JSX.Element;
}

const SigneesListFrame: StatelessComponent<ISigneesList> = props => {
  const {
    showHeader = true,
    showRoleHeader = true,
    render,
    children,
    t,
  } = props;

  return (
    <Table className="m-0">
      {showHeader ? (
        <TableHead>
          <tr>
            <TableHeaderCell>
              {showRoleHeader ? t('NameRole') : t('Name')}
            </TableHeaderCell>
            <TableHeaderCell>{t('Status')}</TableHeaderCell>
          </tr>
        </TableHead>
      ) : null}
      <tbody>{render ? render() : children}</tbody>
    </Table>
  );
};

export default withNamespaces()(SigneesListFrame);
