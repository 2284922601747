import React, { FC, ComponentProps } from 'react';
import DanskeBankLogoSrc from 'src/assets/images/danske-bank-logo.svg';
import RealkreditDenmarkLogoSrc from 'src/assets/images/refsign-logo.svg';
import DanicaDKLogoSrc from 'src/assets/images/danica-logo.svg';
import NordaniaDenmarkLogoSrc from 'src/assets/images/nordania-logo.svg';
import { Logo, LogoLink } from './styles';

type Props = ComponentProps<typeof LogoLink>;

export const DanskeBankLogo: FC<Props> = props => (
  <LogoLink {...props}>
    <Logo src={DanskeBankLogoSrc} alt="Danske bank logo" />
  </LogoLink>
);

export const RealkreditDenmarkLogo: FC<Props> = props => (
  <LogoLink {...props}>
    <Logo src={RealkreditDenmarkLogoSrc} alt="Realkredit Denmark logo" />
  </LogoLink>
);

export const DanicaDKLogo: FC<Props> = props => (
  <LogoLink {...props}>
    <Logo src={DanicaDKLogoSrc} alt="Danica Denmark logo" />
  </LogoLink>
);

export const NordaniaDenmarkLogo: FC<Props> = props => (
  <LogoLink {...props}>
    <Logo src={NordaniaDenmarkLogoSrc} alt="Nordania Denmark logo" />
  </LogoLink>
);
