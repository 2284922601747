import { Title as CardTitle } from 'src/components/Card/Title';
import { Tag as SigningCaseTag } from 'src/components/Tag';
import { ISigningCase } from 'src/models';
import {
  darkBlueLight,
  darkGreen,
  darkRed,
  darkYellow,
} from 'src/styles/colors';
import {
  isSigningCaseReadyToSign,
  isSigningCaseRejected,
  isSigningCaseSigned,
  isSigningCaseWaiting,
} from 'src/utils/SigningCase';
import styled from 'styled-components';
import { ISigningCaseDetailsHeader } from './IPortalSigningCaseDetails';

export const getSigningCaseHeaderColor = (status: ISigningCase['status']) => {
  if (isSigningCaseReadyToSign(status)) {
    return darkBlueLight;
  } else if (isSigningCaseRejected(status)) {
    return darkRed;
  } else if (isSigningCaseSigned(status)) {
    return darkGreen;
  } else if (isSigningCaseWaiting(status)) {
    return darkYellow;
  }

  return darkBlueLight;
};

export const getHeaderColor = (props: ISigningCaseDetailsHeader) =>
  getSigningCaseHeaderColor(props.status);

export const SigningCaseBorder = styled.div`
  border-top: 8px solid ${getHeaderColor};
`;

export const Tag = styled(SigningCaseTag)`
  position: absolute;
  top: -4px;
  right: 0;
  padding: 2px 6px 3px;
  border-bottom-left-radius: 3px;
`;

export const HeaderContainer = styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.typography.header.fontFamily};
  padding: 1em 22px 1.2em;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: inline-block;
  width: 80%;
`;

export const StatusContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  text-align: right;
  width: 20%;
`;

export const SigningCaseTitle = styled(CardTitle)`
  padding: 0;
  text-align: left;
`;

export const Deadline = styled.span`
  opacity: 0.7;
`;
