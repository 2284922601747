import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Card } from 'src/components';
import { MandateLimits } from 'src/models';
import { SigningRules } from '../SigningRules';

interface ISigningRulesCard extends WithNamespaces {
  limits: MandateLimits;
}

const SigningRulesCard: StatelessComponent<ISigningRulesCard> = props => {
  const { t, limits } = props;

  return (
    <Card
      title={t('MandateRules')}
      type="success"
      data-testid={props['data-testid']}
    >
      <SigningRules limits={limits} />
    </Card>
  );
};

export default withNamespaces()(SigningRulesCard);
