import React, { FC } from 'react';
import { Icon } from 'src/components/Icons';

export interface IPortalDeadlineDate {
  date: string;
  className?: string;
}

export const DeadlineDate: FC<IPortalDeadlineDate> = props => {
  const { date, className, children, ...rest } = props;

  return (
    <span className={className} {...rest}>
      <Icon type="clock" data-testid="clock-icon" /> <span>{date}</span>
    </span>
  );
};
