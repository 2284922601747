import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardLoader } from 'src/components';
import { SigningCasesList } from './SigningCasesList';
import { NoAgreementsMessage } from './NoAgreementsMessage';
import { SigningCaseStatusApi } from 'src/models';
import { ISigningCaseListItem } from 'src/models/SigningCase';
import { getRoute } from 'src/routes';
import SigningCaseListActions from 'src/state/SigningCasesList/actions';
import { getAwaitingSigningCases } from 'src/state/SigningCasesList/selectors';
import { RouteComponentProps } from 'react-router-dom';

type RouteProps = RouteComponentProps<any>;

interface StateProps {
  signingCases: ReturnType<typeof getAwaitingSigningCases>;
}

interface DispatchProps {
  openSigningCase: typeof SigningCaseListActions.goSigningCaseDetails;
}

type Props = StateProps & DispatchProps & RouteProps & WithNamespaces;

class AgreementsReady extends Component<Props> {
  private status: SigningCaseStatusApi = 'AwaitingYourSignature';

  render() {
    const { signingCases, t } = this.props;
    const title = t('AgreementsReadyForYouToSign');

    return (
      <Card
        title={title}
        popout={true}
        showNotifications={true}
        data-testid="unsigned-signing-cases"
      >
        {signingCases.isLoading ? (
          <CardLoader data-testid="spinner" />
        ) : signingCases.list.length > 0 ? (
          <SigningCasesList
            documents={signingCases.list}
            type={'NotSigned'}
            onClick={this.onSigningCaseClick}
          />
        ) : (
          <NoAgreementsMessage to={getRoute('archive')} />
        )}
      </Card>
    );
  }

  private onSigningCaseClick = (
    signignCaseListItem: ISigningCaseListItem,
    index: number
  ) => {
    const { openSigningCase, history, location } = this.props;

    openSigningCase({
      history,
      index,
      location,
      signignCaseListItem,
      status: this.status,
    });
  };
}

const connectedComponent = connect(
  state => ({
    signingCases: getAwaitingSigningCases(state),
  }),
  {
    openSigningCase: SigningCaseListActions.goSigningCaseDetails,
  }
)(AgreementsReady);
const ExtendedComponent = withRouter(withNamespaces()(connectedComponent));
export { ExtendedComponent as AgreementsReadyCard };
