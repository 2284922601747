import React, { FC } from 'react';
import {
  AgreementStatusIcon,
  AgreementStatusText,
  IAgreementStatusIcon,
} from 'src/components/PortalList/PortalAgreementStatus';
import { StatusSubtitle } from './styles';

export const SigningCaseStatus: FC<IAgreementStatusIcon> = ({
  color,
  status,
  className,
}) => (
  <span className={className}>
    <AgreementStatusIcon status={status} color={color} />
    <StatusSubtitle>
      <AgreementStatusText status={status} />
    </StatusSubtitle>
  </span>
);
