import Grid from '@material-ui/core/Grid';
import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { IProductArea } from 'src/models';
import ProductArea from '../ProductArea';

type TshowDescription = (index: number) => void;
interface IProductAreas extends WithNamespaces {
  productAreas: IProductArea[];
  showDescription: TshowDescription;
  selected: { [k: string]: boolean };
  onSelect: (id: string, value: boolean) => void;
}

const ProductAreas: StatelessComponent<IProductAreas> = props => {
  const { productAreas, selected } = props;

  return (
    <Grid container={true} spacing={16}>
      {productAreas.map((area, i) => (
        <Grid key={i} item={true} xs={12} lg={6}>
          <ProductArea
            onSelect={onSelect(i, props.onSelect)}
            selected={selected[i] || false}
            productArea={area}
            showDescription={showDescription(i, props.showDescription)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const onSelect = (id: number, callback: any) => (e: any) => callback(id, e);
const showDescription = (index: number, callback: TshowDescription) => () =>
  callback(index);

export default withNamespaces()(ProductAreas);
