import { ArrowIcon } from 'src/components/Icons';
import { darkBlue } from 'src/styles/colors';
import { mediaMaxWidth } from 'src/styles/layout';
import styled from 'styled-components';

export const Input = styled.input<any>`
  display: block;
  width: 100%;
  font-size: inherit;
  padding: 0.75em 1em;
  border: 1px solid #e6e9e8;
  font-family: inherit;
  outline: none;

  &:hover {
    border: 1px solid #cccccc;
  }

  &:focus {
    border: 1px solid #54bfe4;
    background-color: #fff;
  }

  &.error {
    border: 1px solid red;
  }
`;

export const TableCaption = styled.div`
  display: table-caption;
  text-align: left;
  background-color: #02a3df;
  color: white;
  padding: 10px 40px;
`;

const dateStyle = `
  width: 100%;
  text-align: center;
`;

export const Year = styled.div`
  ${dateStyle}
  font-size: 0.8em;
  opacity: 0.7;
`;

export const Month = styled.div`
  ${dateStyle}
`;

const dayThemes = {
  disabled: `
    && {
      color: #dce0e0;
    }
  `,
  // && is used to override weekend styles
  selected: `
    background-color: #02a3df;
    transition-delay: 0.2s;

    && {
      color: #fff;
    }

    &:hover {
      background-color: #02a3df;
    }
  `,
  today: `
    border-radius: 50%;
    border: 1px solid #02a3df;
    font-weight: 400;
  `,
  // && is used to override library styles
  weekend: `
    && {
      color: #EB7C83;
    }
  `,
};

const applyThemes = (modifiers, themes) =>
  Object.keys(modifiers).reduce(
    (styles, modifier) => styles.concat(themes[modifier]),
    ''
  );
const applyDayThemes = (modifiers: any) => applyThemes(modifiers, dayThemes);

export const DayButton = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
  padding: 5px;
  transition: all 0.2s ease-out;
  border-radius: 50%;
  color: ${darkBlue};
  height: 30px;
  width: 30px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  ${mediaMaxWidth.mobile(`
    margin: auto;
    padding: 0px;
  `)}

  ${props => applyDayThemes(props.modifiers)}
`;

export const WeekdayHeader = styled.div`
  display: table-cell;
  padding: 0.5em;
  color: inherit;
  font-weight: bold;
  text-align: center;
  font-size: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const WeekdayAbbr = styled.abbr`
  && {
    text-decoration: none;
  }
`;

//@ts-expect-error
const Arrow = styled(ArrowIcon)`
  position: absolute;
  text-align: center;
  top: 7px;
  padding: 5px;
  border-radius: 50%;
  color: white;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

export const ArrowLeft = styled(Arrow)`
  left: 15px;
`;

export const ArrowRight = styled(Arrow)`
  right: 15px;
`;
