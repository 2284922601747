import React, { FC } from 'react';
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next';
import { LinkProps } from 'react-router-dom';
import { PortalLink } from './styles';
import { GridStyled } from '../styles';

interface IPortalNoAgreements extends LinkProps<any>, WithNamespaces {}

const NoAgreementsMessage: FC<IPortalNoAgreements> = ({ t, to }) => (
  <GridStyled
    container
    justify="center"
    direction="column"
    alignItems="center"
    data-testid="no-signing-cases"
  >
    <p>{t('ThereAreNoPendingAgreementsThatRequireYourSignature')}</p>
    <p>
      <Trans
        i18nKey="SeeTheArchvieToFindOtherAgreements"
        values={{
          link: t('Archive'),
        }}
      >
        <PortalLink to={to} data-testid="archive-link">
          Archive
        </PortalLink>
      </Trans>
    </p>
  </GridStyled>
);

const ExtendedComponent = withNamespaces()(NoAgreementsMessage);
export { ExtendedComponent as NoAgreementsMessage };
