import React, { Component } from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { logOut as logOutAction } from 'src/state/UserSession';
import {
  LogoutButton,
  LogoutIcon,
  LogoutText,
} from './PortalLogoutButton.styles';

interface IProps {
  logOut: typeof logOutAction;
}

class PortalLogoutButton extends Component<IProps> {
  render() {
    const buttonText = t('LogOut');
    return (
      <LogoutButton onClick={this.props.logOut} aria-label={buttonText}>
        <LogoutText>{buttonText}</LogoutText>
        <LogoutIcon type="logout" />
      </LogoutButton>
    );
  }
}

export default connect(
  null,
  {
    logOut: logOutAction,
  }
)(PortalLogoutButton);
