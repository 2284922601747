import React, { FC } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SigningDocumentCover, CoverItem } from 'src/components/Cover';
import { Grid } from '@material-ui/core';
import { Link } from './styles';

interface Props extends WithNamespaces, RouteComponentProps<any> {
  canCreateMandate?: boolean;
}

const CoverSection: FC<Props> = props => {
  const { canCreateMandate = false, t, match } = props;

  return (
    <SigningDocumentCover container={true} alignItems="flex-end">
      {canCreateMandate ? (
        <CoverItem container justify="flex-end">
          <Grid item xs={12} md="auto">
            <Link to={`${match.url}/New`} data-testid="new-mandate-btn">
              {t('MandateCreateButton')}
            </Link>
          </Grid>
        </CoverItem>
      ) : null}
    </SigningDocumentCover>
  );
};

export default withRouter(withNamespaces()(CoverSection));
