import React, { StatelessComponent } from 'react';
import { Li, Ul } from './DashList.styles';

interface IDashList {
  list: any[];
}

const DashList: StatelessComponent<IDashList> = props => {
  const { list } = props;

  return (
    <Ul>
      {list.map((item, i) => (
        <Li key={i}>{item}</Li>
      ))}
    </Ul>
  );
};

export default DashList;
