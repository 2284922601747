import React, { FC } from 'react';
import { Date } from './components';
import { ITag } from './models';
import { TagContainer } from './Tag.styles';

export const Tag: FC<ITag> = ({ color, className, date, status, ...rest }) => {
  return (
    <TagContainer className={className} color={color} {...rest}>
      <Date date={date} status={status} />
    </TagContainer>
  );
};
