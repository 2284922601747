import React, { FC } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { TableCellBig, TableCellLast } from 'src/components';
import { Link } from 'src/components/Links';
import { ISigningMandateDetails } from 'src/models';
import { ProductAreas } from 'src/state/Mandates';
import { MandateDate, TableCellMobile, TableCellTitle } from './components';
import { MandatesTableRow as TableRow, Title } from './MandatesTableRow.styles';
import {
  isMandateRevoked,
  isMandateNew,
  isMandateActive,
  isMandateInactive,
} from 'src/utils/Mandate';
import { MandatesListType } from 'src/pages/Mandates/models';

export interface Props extends RouteComponentProps<any>, WithNamespaces {
  mandate: ISigningMandateDetails;
  type: MandatesListType;
}

export const MandatesTableRow: FC<Props> = props => {
  const { mandate, location, t, type } = props;
  const creationDate = getMandateCreationDate(mandate, t);
  const expirationDate = getMandateExpirationDate(mandate, t);
  const productAreas = ProductAreas.displayProductAreas(
    t,
    mandate.mandateLimits,
    mandate.requiredMandatedSignatoriesCount,
    mandate.mandatedSignatories.length
  );

  return (
    <TableRow data-testid={props['data-testid']}>
      <TableCellTitle type={type}>
        <Link to={`${location.pathname}/${mandate.id}`}>
          <Title
            title={productAreas}
            subtitle={ProductAreas.joinMandatedSignatoriesNames(
              mandate.mandatedSignatories
            )}
          />
        </Link>
      </TableCellTitle>
      <TableCellBig>
        <Link to={`${location.pathname}/${mandate.id}`}>
          <MandateDate date={creationDate} data-testid="mandate-valid-from" />
        </Link>
      </TableCellBig>
      <TableCellLast align="left" hide>
        <Link to={`${location.pathname}/${mandate.id}`}>
          <MandateDate date={expirationDate} data-testid="mandate-valid-to" />
        </Link>
      </TableCellLast>
      <TableCellMobile type={type}>
        <Link to={`${location.pathname}/${mandate.id}`}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <MandateDate style={{ fontSize: '0.8em' }} date={creationDate} />
            </Grid>
            <Grid
              container
              item
              xs={6}
              style={{ padding: '10px' }}
              justify="flex-end"
            >
              <Grid item xs="auto">
                <MandateDate
                  style={{ fontSize: '0.8em' }}
                  date={expirationDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </TableCellMobile>
    </TableRow>
  );
};

export default withRouter(withNamespaces()(MandatesTableRow));

export const isDateValid = (date: string) =>
  parseInt(moment(date).format('YYYY'), 10) < 9000;

export const isExpired = (expirationDate: string) =>
  moment(expirationDate) <= moment();

export const getMandateCreationDate = (
  mandate: ISigningMandateDetails,
  t: WithNamespaces['t']
): string => {
  if (isMandateRevoked(mandate.state)) {
    return t('MandateRevoked');
  }
  if (
    !isDateValid(mandate.creationDate) ||
    isExpired(mandate.expirationDate) ||
    isMandateInactive(mandate.state)
  ) {
    return '';
  }
  if (isMandateNew(mandate.state)) {
    return t('MandateStatusPending');
  }
  return mandate.creationDate;
};

export const getMandateExpirationDate = (
  mandate: ISigningMandateDetails,
  t: WithNamespaces['t']
): string => {
  if (!isMandateActive(mandate.state) || isExpired(mandate.expirationDate)) {
    return '';
  }
  if (!isDateValid(mandate.expirationDate)) {
    return t('ValidTillRevoked');
  }
  return mandate.expirationDate;
};
