import React, { StatelessComponent } from 'react';
import { ModalBody } from './Body.styles';
import { IModalBody } from './models';

interface IDialogModalBody extends IModalBody {
  message?: string;
  warning?: string;
}

const DialogModalBody: StatelessComponent<IDialogModalBody> = props => {
  const { message, warning, children, ...modalProps } = props;

  return (
    <ModalBody {...modalProps}>
      {message ? <p>{message}</p> : null}
      {warning ? <p>{warning}</p> : null}
      {children}
    </ModalBody>
  );
};

export default DialogModalBody;
