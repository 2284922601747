import { TextAlignProperty } from 'csstype';
import { darkBlue } from 'src/styles/colors';
import styled from 'styled-components';
import { TModal } from '../../models';

interface IHeader {
  type?: TModal;
  textAlign?: TextAlignProperty;
}

const Header = styled.div<IHeader>`
  background-color: ${props =>
    props.type === 'Warning' ? '#fbb273' : 'white'};
  padding: 15px;
  border-bottom: 1px solid ${darkBlue};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  text-align: ${props => props.textAlign || 'unset'};
`;

export const Title = styled.span`
  font-family: 'DanskeHuman', 'Tahoma', Sans-Serif;
  font-size: 1.2em;
  color: ${darkBlue};
  text-align: left;
`;

export default Header;
