import React, { StatelessComponent } from 'react';
import { Icon } from 'src/components/Icons';
import { ISignee } from './ISignee';
import { IconContainer } from './PortalSigneesList.styles';

interface ISigneeActionIcon {
  actionRole: ISignee['actionRole'];
}

const SigneeActionIcon: StatelessComponent<ISigneeActionIcon> = props => {
  const { actionRole } = props;

  return (
    <IconContainer>
      {actionRole === 'Signee' ? (
        <Icon type="ok" />
      ) : actionRole === 'Rejector' ? (
        <Icon type="delete" />
      ) : null}
    </IconContainer>
  );
};

export default SigneeActionIcon;
