import React, { StatelessComponent } from 'react';
import { Icon } from 'src/components/Icons';
import GenericNotification, {
  IGenericNotification,
} from '../GenericNotification';
import { NotificationText } from '../Notifications.styles';

type TErrorNotification = Pick<
  IGenericNotification,
  Exclude<keyof IGenericNotification, 'type'>
>;

const ErrorNotification: StatelessComponent<TErrorNotification> = props => {
  const { text, ...notificationProps } = props;

  return (
    <GenericNotification
      type={'Warning'}
      Icon={<Icon type={'warning'} />}
      Text={<NotificationText type="warning">{text}</NotificationText>}
      {...notificationProps}
    />
  );
};

export default ErrorNotification;
