import React, { FC } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Modal } from '../Modal';
import { Footer } from './Footer';
import { Message } from './Message';

interface Props extends WithNamespaces {
  onCancel: () => void;
  onConfirm: () => void;
  onCountdownComplete: () => void;
  timeout: number;
}

const SessionTimeoutDialog: FC<Props> = ({
  t,
  timeout,
  onConfirm,
  onCancel,
  onCountdownComplete,
  ...rest
}) => {
  const footer = <Footer onConfirm={onConfirm} onCancel={onCancel} />;
  return (
    <Modal
      title={t('SessionTimeout')}
      onConfirm={onConfirm}
      onCancel={onCancel}
      {...rest}
      footer={footer}
    >
      <Message timeout={timeout} onCountdownComplete={onCountdownComplete} />
    </Modal>
  );
};

const ExtentedComponent = withNamespaces()(SessionTimeoutDialog);
export { ExtentedComponent as SessionTimeoutDialog };
