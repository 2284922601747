import moment from 'moment';
import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { MandateState } from 'src/models';
import { ValidityDate } from './components';
import { ValidityContainer } from './Validity.styles';

interface IValidity extends WithNamespaces {
  from: string;
  to: string;
  status: MandateState;
}

const Validity: StatelessComponent<IValidity> = props => {
  const { from, to, t, status } = props;

  return (
    <ValidityContainer data-testid={props['data-testid']}>
      {status === 'Deleted' ? (
        <span>{t('MandateRevoked')}</span>
      ) : status === 'New' ? (
        moment(to).date() < moment.now() ? (
          <span>{t('Dash')}</span>
        ) : (
          <span>{t('MandateStatusPending')}</span>
        )
      ) : (
        <>
          <ValidityDate title={t('MandateValidFrom')} content={from} />
          <ValidityDate title={t('ValidTill')} content={to} />
        </>
      )}
    </ValidityContainer>
  );
};

export default withNamespaces()(Validity);
