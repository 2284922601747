import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Label } from 'src/components/Input';
import { OnStatusClick, Status } from './models';
import { StatusButton, StatusButtonMenu } from './styles';

interface Props {
  status: Status;
  onClick: (status: Status) => void;
}

type StatusSelectProps = Props & WithNamespaces;

const Select: StatelessComponent<StatusSelectProps> = props => {
  const { onClick, t, status } = props;
  const ArchiveStatus = 'ArchiveStatus';
  const All = 'All';
  const Signed = 'Signed';
  const NotSigned = 'NotSigned';

  const onStatusClick: OnStatusClick = e => onClick(e.currentTarget.value);

  return (
    <div data-testid={props['data-testid']}>
      <Label as="span">{t('Status')}</Label>
      <StatusButtonMenu role="menu">
        <StatusButton
          type="button"
          role="menuitem"
          value={All}
          isSelected={status === All}
          onClick={onStatusClick}
        >
          {t(ArchiveStatus + All)}
        </StatusButton>
        <StatusButton
          type="button"
          role="menuitem"
          value={Signed}
          isSelected={status === Signed}
          onClick={onStatusClick}
        >
          {t(ArchiveStatus + Signed)}
        </StatusButton>
        <StatusButton
          type="button"
          role="menuitem"
          value={NotSigned}
          isSelected={status === NotSigned}
          onClick={onStatusClick}
        >
          {t(ArchiveStatus + NotSigned)}
        </StatusButton>
      </StatusButtonMenu>
    </div>
  );
};

const StatusSelect = withNamespaces()(Select);
export { StatusSelect };
