import React, { FC, ComponentProps } from 'react';
import { Grid } from '@material-ui/core';
import { PortalButton } from 'src/components/Buttons';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Modal } from './Modal';

interface Props extends ComponentProps<typeof Modal>, WithNamespaces {
  message: string;
}

const WarningModal: FC<Props> = ({ title, onCancel, message, children, t }) => (
  <Modal
    title={title || t('WarningDialogTitle')}
    onCancel={onCancel}
    footer={
      <Grid container justify="center">
        <Grid item>
          <PortalButton colorType="secondary" onClick={onCancel}>
            {t('Close')}
          </PortalButton>
        </Grid>
      </Grid>
    }
  >
    <p>{message}</p>
    {children}
  </Modal>
);

const ExtendedComponent = withNamespaces()(WarningModal);
export { ExtendedComponent as WarningModal };
