import React, { StatelessComponent, HTMLAttributes } from 'react';
import { ActionMenuContainer } from './ActionMenu.styles';
import Grid from '@material-ui/core/Grid';

type Props = HTMLAttributes<HTMLElement> &
  Partial<{
    leftSide: JSX.Element;
    rightSide: JSX.Element;
  }>;

const ActionMenu: StatelessComponent<Props> = ({
  className,
  leftSide: Left = null,
  rightSide: Right = null,
  children,
  ...rest
}) => {
  return (
    <ActionMenuContainer
      className={className}
      container={true}
      wrap="wrap"
      {...rest}
    >
      <Grid item xs={12} md={6}>
        {Left}
      </Grid>
      <Grid item xs={12} md={6}>
        {Right}
      </Grid>
      {children}
    </ActionMenuContainer>
  );
};

export default ActionMenu;
