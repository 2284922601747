import React, { ComponentType, StatelessComponent } from 'react';
import { Subtitle } from '../Title';
import {
  HeaderContainer,
  StatusContainer,
  TitleContainer,
  CardTitle,
} from './Header.styles';

interface IHeader {
  title: string;
  subtitle: string;
  StatusComponent?: ComponentType<any>;
  statusComponentProps?: object;
}

export const CardHeader: StatelessComponent<IHeader> = props => {
  const {
    title,
    subtitle,
    StatusComponent,
    statusComponentProps = {},
    children,
    ...rest
  } = props;

  return (
    <HeaderContainer {...rest}>
      <TitleContainer>
        <CardTitle>{title}</CardTitle>
        <Subtitle>{subtitle}</Subtitle>
      </TitleContainer>
      {StatusComponent ? (
        <StatusContainer>
          <StatusComponent
            {...statusComponentProps}
            data-testid="header-status"
          />
        </StatusContainer>
      ) : null}
    </HeaderContainer>
  );
};
