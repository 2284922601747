import React, { FC } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Table, TableHead, TableHeaderCell } from 'src/components';

const DocumentsList: FC<WithNamespaces> = ({ children, t }) => {
  return (
    <Table>
      <TableHead>
        <tr>
          <TableHeaderCell>{t('AgreementDocuments')}</TableHeaderCell>
        </tr>
      </TableHead>
      <tbody>{children}</tbody>
    </Table>
  );
};

const ExtendedComponent = withNamespaces()(DocumentsList);
export { ExtendedComponent as DocumentsList };
