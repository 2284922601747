import { flowRight } from 'lodash';
import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { ISigningCaseListItem } from 'src/models';

export interface IAgreementStatusText extends WithNamespaces {
  status: ISigningCaseListItem['status'];
}

const AgreementStatusText: StatelessComponent<IAgreementStatusText> = props => {
  const { status, t } = props;

  return (
    <span>
      {flowRight(
        t,
        getTextFromAgreementStatus
      )(status)}
    </span>
  );
};

const getTextFromAgreementStatus = (status: ISigningCaseListItem['status']) => {
  return status === 'NotSigned' || status === 'PartiallySigned'
    ? 'AgreementYouMustSign'
    : status === 'Signed'
    ? 'AgreementSigned'
    : status === 'Expired'
    ? 'AgreementExpired'
    : status === 'Cancelled'
    ? 'AgreementCancelled'
    : status === 'CancelledBySignatory'
    ? 'AgreementRejected'
    : status === 'Manual'
    ? 'AgreementManual'
    : status === 'SignedByCurrentContractingParty'
    ? 'AgreementAwaitingOtherContractingParty'
    : status === 'Updating'
    ? 'AgreementUpdating'
    : '';
};

export default withNamespaces()(AgreementStatusText);
