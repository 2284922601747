import React, { FC } from 'react';
import { CircleSpan } from './Circle.styles';
import { ICircleStep } from '../../components/types';

const Circle: FC<ICircleStep> = props => {
  const { active, activeStep } = props;

  return (
    <CircleSpan active={active} activeStep={activeStep}>
      {props.children}
    </CircleSpan>
  );
};

export default Circle;
