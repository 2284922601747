import { Grid } from '@material-ui/core';
import React, { StatelessComponent } from 'react';
import { GridStyled } from 'src/styles/layout';
import CircleButton from '../CircleButton';

interface IProps {
  editable?: boolean;
  needsRefresh?: boolean;
  disabled: boolean;
  toggleEdit: () => void;
  removeSignatory: () => void;
  refresh: () => void;
}

const SignatoryMenu: StatelessComponent<IProps> = props => {
  const {
    disabled,
    toggleEdit,
    removeSignatory,
    needsRefresh = false,
    editable = false,
    refresh,
  } = props;

  const padding = 0;

  return (
    <Grid container={true} justify="center">
      {editable ? (
        !disabled ? (
          <GridStyled item={true} padding={padding}>
            <CircleButton iconType="ok" type="button" onClick={refresh} />
          </GridStyled>
        ) : (
          <GridStyled item={true} padding={padding}>
            <CircleButton
              type="button"
              iconType="pencil"
              onClick={toggleEdit}
            />
          </GridStyled>
        )
      ) : null}
      {needsRefresh ? (
        <GridStyled item={true} padding={padding}>
          <CircleButton type="button" iconType="refresh" onClick={refresh} />
        </GridStyled>
      ) : null}
      <GridStyled item={true} padding={padding}>
        <CircleButton
          iconType="delete"
          type="button"
          onClick={removeSignatory}
        />
      </GridStyled>
    </Grid>
  );
};

export default SignatoryMenu;
