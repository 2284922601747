import { darkBlue, darkRed } from 'src/styles/colors';
import styled from 'styled-components';
import Button from '../Button';

export const CloseBtn = styled(Button)`
  color: ${({ color }) => (color === 'darkBlue' ? darkBlue : darkRed)};

  &:hover {
    color: ${({ color }) => (color === 'darkBlue' ? darkBlue : darkRed)};
    cursor: pointer;
  }
`;
