import { call, put, takeEvery } from 'redux-saga/effects';
import * as api from 'src/state/api';
import { notificationsManager } from 'src/state/Notifications';
import SignActions from './actions';
import ActionTypes from './types';

type IsSignatureSuccessfulParams = ReturnType<typeof SignActions.signDocument>;
function* isSignatureSuccessful(params: IsSignatureSuccessfulParams) {
  try {
    yield put(SignActions.signDocumentRequest());

    const isSuccessful = yield call(
      api.isSignatureSuccessful,
      params.payload.signatureId,
      params.payload.signingCaseId,
      params.payload.contractingPartyId
    );

    yield put(SignActions.signDocumentSuccess(isSuccessful.data.data));
    yield put(
      notificationsManager.addNotification({
        text: 'YouHaveSuccessfullySignedTheAgreement',
        type: 'Success',
      })
    );
  } catch (e) {
    yield put(SignActions.signDocumentFailure());
    yield put(notificationsManager.addNotification(e));
  }
}

export function* watch() {
  yield takeEvery(ActionTypes.SIGN_DOCUMENT, isSignatureSuccessful);
}
