import styled from 'styled-components';
import React from 'react';

type IconType =
  | 'clock'
  | 'waiting'
  | 'warning'
  | 'logout'
  | 'ok'
  | 'menu'
  | 'download'
  | 'delete'
  | 'person'
  | 'refresh'
  | 'warning'
  | 'pencil';

interface IconProps extends React.HTMLAttributes<HTMLElement> {
  type: IconType;
}

export const Icon = styled.i.attrs((props: IconProps) => ({
  className: `${props.className || ''} generic-icon ${props.type}`.trim(),
}))<IconProps>`
  color: ${({ color }) => color};
`;
