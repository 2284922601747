import React, { ComponentType, StatelessComponent } from 'react';
import { TNotificationType } from 'src/models';
import {
  ButtonContainer,
  CloseButton,
  IconContainer,
  NotificationContainer,
  NotificationText,
  TextContainer,
} from './Notifications.styles';

export interface IGenericNotification {
  type: TNotificationType;
  text?: string | JSX.Element;
  Text?: JSX.Element;
  Icon?: JSX.Element;
  CloseButtonComponent?: ComponentType;
  onClose?: () => void;
}

const GenericNotification: StatelessComponent<IGenericNotification> = props => {
  const { type, Text, text, Icon, CloseButtonComponent, onClose } = props;

  return (
    <NotificationContainer type={type}>
      {Icon ? <IconContainer>{Icon}</IconContainer> : null}
      <TextContainer>
        {Text ? (
          Text
        ) : text ? (
          <NotificationText>{text}</NotificationText>
        ) : null}
      </TextContainer>
      <ButtonContainer>
        {CloseButtonComponent ? (
          <CloseButtonComponent />
        ) : (
          <CloseButton onClick={onClose} />
        )}
      </ButtonContainer>
    </NotificationContainer>
  );
};

export default GenericNotification;
