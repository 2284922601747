import React, { FC, HTMLAttributes } from 'react';
import { ISigningCase } from 'src/models';
import { getSigningCaseHeaderColor } from '../../services';
import ContractingPartyStatus from '../ContractingPartyStatus';
import {
  HeaderContainer,
  SigningCaseBorder,
  SigningCaseTitle,
  StatusContainer,
  Tag,
  TitleContainer,
} from './SigningCaseHeader.styles';
import { Subtitle as CardSubtitle } from 'src/components/Card/Title';

interface Props extends HTMLAttributes<HTMLDivElement> {
  title: string;
  subTitle: string;
  status: ISigningCase['status'];
  deadline: string;
}

const SigningCaseHeader: FC<Props> = ({
  title,
  subTitle,
  status,
  deadline,
  ...rest
}) => {
  return (
    <SigningCaseBorder status={status} {...rest}>
      <HeaderContainer>
        <Tag
          color={getSigningCaseHeaderColor(status)}
          date={deadline}
          status={status}
          data-testid="signing-case-tag"
        />
        <TitleContainer>
          <SigningCaseTitle title={title}>{title}</SigningCaseTitle>
          <CardSubtitle title={subTitle}>{subTitle}</CardSubtitle>
        </TitleContainer>
        <StatusContainer>
          <ContractingPartyStatus status={status} />
        </StatusContainer>
      </HeaderContainer>
    </SigningCaseBorder>
  );
};

export default SigningCaseHeader;
