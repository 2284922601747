import React, { StatelessComponent, HTMLAttributes } from 'react';
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next';
import { Content } from 'src/styles/layout';
import { FooterContainer, TelNumber, Text } from './Footer.styles';

interface Props extends WithNamespaces, HTMLAttributes<HTMLElement> {
  hasTwoPhoneNumbers: boolean;
  userId?: string;
}

export const Footer: StatelessComponent<Props> = ({
  t,
  hasTwoPhoneNumbers,
  userId,
  ...rest
}) => {
  return (
    <FooterContainer {...rest}>
      <Content>
        <Text>
          {!hasTwoPhoneNumbers && (
            <Trans
              i18nKey="ContactsCopyright"
              components={[
                <TelNumber
                  key={t('ContactNumber')}
                  href={`tel:${t('ContactNumber')}`}
                >
                  {t('ContactNumber')}
                </TelNumber>,
              ]}
              values={{
                ContactNumber: t('ContactNumber'),
              }}
            />
          )}
          {hasTwoPhoneNumbers && (
            <Trans
              i18nKey="ContactsCopyright"
              components={[
                <TelNumber
                  key={t('ContactNumberPrivate')}
                  href={`tel:${t('ContactNumberPrivate')}`}
                >
                  {t('ContactNumberPrivate')}
                </TelNumber>,
                <TelNumber
                  key={t('ContactNumberBusiness')}
                  href={`tel:${t('ContactNumberBusiness')}`}
                >
                  {t('ContactNumberBusiness')}
                </TelNumber>,
              ]}
              values={{
                ContactNumberPrivate: t('ContactNumberPrivate'),
                ContactNumberBusiness: t('ContactNumberBusiness'),
              }}
            />
          )}
          {userId && ` - ${t('UserId', { userId: userId })}`}
        </Text>
      </Content>
    </FooterContainer>
  );
};

export default withNamespaces()(Footer);
