import { Collapse } from '@material-ui/core';
import { lightWhite, lightWhite2 } from 'src/styles/colors';
import { defaultTransition, mediaMaxWidth2 } from 'src/styles/layout';
import styled from 'styled-components';

interface IMenu {
  show: boolean;
}

export const Menu = styled.ul<IMenu>`
  display: none;
  left: ${props => (props.show ? '0' : '-100%')};
  padding: 0;
  margin: 0;

  ${mediaMaxWidth2.large`
    display: block;
    visibility: ${props => (props.show ? 'visible' : 'hidden')};
    position: fixed;
    width: 100%;
    top: 54px;
    bottom: 0px;
    background-color: ${({ theme }) => theme.pallete.backgrounds.hamburgerMenu};
    color: #fff;
    overflow: auto;
    transition: ${defaultTransition};
    z-index: 1;
  `}
`;

export const Item = styled.li`
  ${mediaMaxWidth2.large`
    display: flex;
    text-align: center;
    border-bottom: 1px solid ${lightWhite2};
    list-style: none;
    margin: 0;
    padding: 0;
  `}

  &:hover {
    background-color: ${lightWhite};
  }
`;

export const ItemElement = styled.div`
  display: flex;
  justify-content: center;
  flex: auto;
  border: none;
  background-color: transparent;
  border-bottom: 0px;
  color: #fff;
  font-size: 1.5em;
  padding: 17px 20px;
  transition: ${defaultTransition};
  outline: none;
  position: relative;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;

export const ItemText = styled.span`
  flex: auto;
`;

export const ItemIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 20px;
  cursor: pointer;
`;

export const CollapseStyled = styled(Collapse)`
  padding: 0;
`;
