import { PortalButton } from 'src/components/Buttons';
import { mediaMaxWidth2 } from 'src/styles/layout';
import styled from 'styled-components';
import { CloseButton } from 'src/components/Buttons';

export const Button = styled(PortalButton)`
  ${mediaMaxWidth2.mobile`
    width: 100%;
  `}
`;

export const CloseBtn = styled(CloseButton)`
  font-size: 1.4em;
`;
