import { t } from 'i18next';
import React, { StatelessComponent } from 'react';
import { Processing } from 'src/components/Feedback';

export const LoadingScreen: StatelessComponent = () => {
  return (
    <Processing
      message={t('MandateProcessing')}
      title={t('MandateCreateProcessing')}
    />
  );
};
