import styled from 'styled-components';

interface ISection {
  popout: boolean;
}

export const Section = styled.section<ISection>`
  margin-top: ${props => (props.popout ? '-60px' : '0px')};
`;

export const ListContainer = styled.div`
  display: inline-block;
  margin: 0px;
  padding: 0px;
  width: 100%;
  position: relative;
`;

export const EmptyListContainer = styled(ListContainer)`
  padding: 20px;
  text-align: center;
`;

type Theme = 'primary' | 'success' | 'error' | 'default';

interface CardProps {
  type?: Theme;
  theme: any;
}

export const Container = styled.div<CardProps>`
  font-size: inherit;
  display: inline-block;
  position: relative;
  margin: 0px;
  padding: 10px 15px;
  width: 100%;
  background-color: ${({ theme, type }) =>
    type && type !== 'default' ? theme.pallete.backgrounds[type] : 'white'};
  padding: 0;
  box-shadow: 0px 2px 4px 0px rgba(57, 67, 68, 0.2);
  margin-bottom: 1.5em;
`;
