import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { ISigningRules } from '../ISigningRules';
import { Description, Rule, Rules } from './SigningRulesList.styles';

interface ISigningRulesList extends ISigningRules, WithNamespaces {}

const SigningRulesList: StatelessComponent<ISigningRulesList> = props => {
  const { className, rules, t } = props;

  if (rules.length) {
    return (
      <div className={className}>
        <Description>
          {t(
            'TheSigningOfThisAgreementIsCompleteWhenTheSignaturesMatchOneOfThe' +
              'FollowingRules'
          )}
        </Description>
        <Rules>
          {rules.map((signingRule, index) => (
            <Rule key={index}>{signingRule.rule}</Rule>
          ))}
        </Rules>
      </div>
    );
  } else {
    return null;
  }
};

export default withNamespaces()(SigningRulesList);
