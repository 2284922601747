import React, { ComponentProps, StatelessComponent } from 'react';
import { Button } from './styles';

type Props = ComponentProps<typeof Button> & {
  title?: string;
};

export const PortalButton: StatelessComponent<Props> = ({
  title,
  children,
  colorType = 'primary',
  ref,
  ...buttonsProps
}) => {
  return (
    <Button colorType={colorType} {...buttonsProps}>
      {title || children || null}
    </Button>
  );
};
