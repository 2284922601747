import { Link } from 'react-router-dom';
import { darkBlueLight } from 'src/styles/colors';
import styled from 'styled-components';

export const PortalLink = styled(Link)`
  color: ${darkBlueLight};

  &:hover {
    color: ${darkBlueLight};
  }
`;
