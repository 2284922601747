import Grid from '@material-ui/core/Grid';
import React, { FC, HTMLAttributes } from 'react';
import { TableRow } from 'src/components/PortalList/PortalList.styles';
import DocumentTitle from 'src/components/PortalList/PortalSigningCaseDetails/DocumentTitle';
import { IDocumentResponse, ISigningCase } from 'src/models';
import { GridStyled } from 'src/styles/layout';
import { DocumentStatus } from './DocumentStatus';
import { GridIcon, GridRow } from './styles';
import { FileIcon, TableCell } from 'src/components';
import { getDocumentTitleHash } from '../services';
import { isSigningCaseSigned } from 'src/utils/SigningCase';

interface Props extends HTMLAttributes<HTMLTableRowElement> {
  document: IDocumentResponse;
  status: ISigningCase['status'];
  downloadLink: string;
}

export const DocumentsListItem: FC<Props> = ({
  document,
  status,
  downloadLink,
  ...rest
}) => {
  return (
    <TableRow {...rest}>
      <TableCell>
        <GridRow
          container
          wrap="nowrap"
          isRead={document.isRead}
          status={status}
        >
          <GridIcon
            container
            item
            xs
            padding={12}
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <FileIcon type={document.mimeType} />
            </Grid>
            <GridStyled item padding={4} screenDown="tabletSmall">
              <DocumentStatus
                isRead={document.isRead}
                showText={false}
                canDownload={canDownload(status, document.isRead)}
                downloadLink={downloadLink}
              />
            </GridStyled>
          </GridIcon>
          <GridStyled container item padding={12}>
            <Grid item={true} md={10}>
              <DocumentTitle
                name={document.name}
                hash={getDocumentTitleHash(document) || ''}
                isBold={!document.isRead}
              />
            </Grid>
            <GridStyled
              container
              item
              md={2}
              justify="flex-end"
              screenUp="tabletSmall"
              alignItems="center"
            >
              <Grid item={true}>
                <DocumentStatus
                  isRead={document.isRead}
                  showText={true}
                  canDownload={canDownload(status, document.isRead)}
                  downloadLink={downloadLink}
                />
              </Grid>
            </GridStyled>
          </GridStyled>
        </GridRow>
      </TableCell>
    </TableRow>
  );
};

const canDownload = (
  status: ISigningCase['status'],
  isDocumentRead: IDocumentResponse['isRead']
) => isSigningCaseSigned(status) && isDocumentRead;
