import React, { FC } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { Table, TableHead } from 'src/components';
import { ISigningMandateDetails } from 'src/models';
import { MandatesList, MandatesTableLineBreak } from './components';
import { TableHeaderCell } from './MandatesTable.styles';

interface IMandatesTable extends WithNamespaces {
  activeMandates: ISigningMandateDetails[];
  inactiveMandates: ISigningMandateDetails[];
}

const MandatesTable: FC<IMandatesTable> = ({
  t,
  activeMandates,
  inactiveMandates,
}) => (
  <Table>
    <TableHead>
      <tr>
        <TableHeaderCell>{t('Title')}</TableHeaderCell>
        <TableHeaderCell>{t('MandateValidFrom')}</TableHeaderCell>
        <TableHeaderCell>{t('ValidTill')}</TableHeaderCell>
      </tr>
    </TableHead>
    <tbody>
      <MandatesList
        list={activeMandates}
        type="Active"
        data-testid="active-mandate"
      />
      {inactiveMandates.length && activeMandates.length ? (
        <MandatesTableLineBreak />
      ) : null}
      <MandatesList
        list={inactiveMandates}
        type="Inactive"
        data-testid="inactive-mandate"
      />
    </tbody>
  </Table>
);

export default withNamespaces()(MandatesTable);
