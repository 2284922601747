enum ActionTypes {
  FETCH_SIGNING_CASE = 'SigningCaseDetails/FETCH_SIGNING_CASE',
  FETCH_SIGNING_CASE_REQUEST = 'SigningCaseDetails/FETCH_SIGNING_CASE_REQUEST',
  FETCH_SIGNING_CASE_SUCCESS = 'SigningCaseDetails/FETCH_SIGNING_CASE_SUCCESS',
  FETCH_SIGNING_CASE_FAILURE = 'SigningCaseDetails/FETCH_SIGNING_CASE_FAILURE',
  RESET_OPENED_SIGNING_CASE = 'SigningCaseDetails/RESET_OPENED_SIGNING_CASE',
  MARK_DOCUMENT_READ = 'SigningCaseDetails/MARK_DOCUMENT_READ',
  MARK_DOCUMENT_READ_SUCCESS = 'SigningCaseDetails/MARK_DOCUMENT_READ_SUCCESS',
}

export default ActionTypes;
