import React, { FC, ComponentProps } from 'react';
import { Modal } from './Modal';

interface Props extends ComponentProps<typeof Modal> {
  message?: string;
  warning?: string;
}

export const DialogModal: FC<Props> = props => {
  const { title, onCancel, onConfirm, message, warning } = props;

  return (
    <Modal title={title} onCancel={onCancel} onConfirm={onConfirm}>
      {message && <p>{message}</p>}
      {warning && <p>{warning}</p>}
    </Modal>
  );
};
