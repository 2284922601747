import { AgreementStatusIcon } from 'src/components/PortalList/PortalAgreementStatus';
import { mediaMaxWidth2 } from 'src/styles/layout';
import styled from 'styled-components';
import { getSigningCaseHeaderColor } from '../../services';
import { IContractingPartyStatus } from './IContractingPartyStatus';

export const StatusIcon = styled(AgreementStatusIcon)`
  font-size: 2em;
`;

export const StatusParagraph = styled.p<IContractingPartyStatus>`
  color: ${({ status }) => getSigningCaseHeaderColor(status)};
  margin-top: 0.3em;

  ${mediaMaxWidth2.tabletSmall`
    display: none;
  `}
`;
