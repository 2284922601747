import React, { StatelessComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { DocumentName, DocumentNameSubtitle } from './DocumentTitle.styles';

interface Props extends WithNamespaces {
  name: string;
  hash?: string;
  isBold?: boolean;
}

const DocumentTitle: StatelessComponent<Props> = props => {
  const { hash, name, t, isBold = false } = props;
  const Title = (
    <DocumentName data-testid="document-name" isBold={isBold}>
      {name}
    </DocumentName>
  );

  if (hash) {
    return (
      <>
        {Title}
        <DocumentNameSubtitle data-testid="document-subtitle">
          {`${t('DocumentKey')}: ${hash}`}
        </DocumentNameSubtitle>
      </>
    );
  } else {
    return Title;
  }
};

export default withNamespaces()(DocumentTitle);
