import React, { MouseEvent, StatelessComponent } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { ISigningCaseListItem } from 'src/models';
import { SigningCasesListItem } from './SigningCasesListItem';
import { Table, TableHead, TableHeaderCell } from 'src/components';

type ListType = 'Archived' | 'NotSigned' | 'Waiting';

interface Props extends WithNamespaces {
  documents: ISigningCaseListItem[];
  type?: ListType;
  onClick?: (document: ISigningCaseListItem, index: number) => void;
}

const SigningCasesList: StatelessComponent<Props> = props => {
  const { documents, type, onClick, t } = props;

  const onSigningCaseClick = (index: number) => (
    e: MouseEvent,
    document: ISigningCaseListItem
  ) => {
    if (onClick) {
      onClick(document, index);
    }
  };

  const onSigningCaseKeyboardClick = (index: number) => (
    e: React.KeyboardEvent,
    document: ISigningCaseListItem
  ) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      if (onClick) {
        onClick(document, index);
      }
    }
  };

  return (
    <Table data-testid={props['data-testid']}>
      <TableHead>
        <tr>
          <TableHeaderCell>{t('Title')}</TableHeaderCell>
          <TableHeaderCell>
            {type === 'Archived' ? t('DateArchived') : t('DeadlineForSigning')}
          </TableHeaderCell>
          <TableHeaderCell>{t('Status')}</TableHeaderCell>
        </tr>
      </TableHead>
      <tbody>
        {documents.map((document, index) => {
          return (
            <SigningCasesListItem
              key={index}
              document={document}
              onClick={onSigningCaseClick(index)}
              onKeyDown={onSigningCaseKeyboardClick(index)}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

const ExtendedComponent = withNamespaces()(SigningCasesList);
export { ExtendedComponent as SigningCasesList };
