import React, { FC } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Subtitle } from './styles';
import { IconStyled } from './styles';
import { grey, darkBlue } from 'src/styles/colors';
import { Link } from 'src/components/Text';

interface Props extends GridProps, WithNamespaces {
  isRead: boolean;
  canDownload?: boolean;
  showText?: boolean;
  downloadLink: string;
}

const DocumentStatus: FC<Props> = props => {
  const {
    canDownload = false,
    isRead,
    showText = true,
    downloadLink,
    t,
  } = props;

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs="auto">
        {isRead && !canDownload ? (
          <IconStyled data-testid="ok-icon" type="ok" color={grey} />
        ) : canDownload ? (
          <Link href={downloadLink} onClick={e => e.stopPropagation()}>
            <IconStyled data-testid="download-icon" type="download" />
          </Link>
        ) : (
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            data-testid="external-link"
          />
        )}
      </Grid>
      {showText && (
        <Grid item>
          {canDownload ? (
            <Link href={downloadLink} onClick={e => e.stopPropagation()}>
              {t('Download')}
            </Link>
          ) : isRead ? (
            <Subtitle>{t('Seen')}</Subtitle>
          ) : (
            <Subtitle style={{ color: darkBlue }}>{t('Open')}</Subtitle>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const ExtendedComponent = withNamespaces()(DocumentStatus);
export { ExtendedComponent as DocumentStatus };
