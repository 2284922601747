import styled from 'styled-components';
import { GridStyled } from 'src/styles/layout';
import Grid, { GridProps } from '@material-ui/core/Grid';
import React from 'react';
import {
  darkBlue,
  grey,
  transparentBlue,
  darkGreen,
  darkRed,
} from 'src/styles/colors';
import { ISigningCase } from 'src/models';
import {
  isSigningCaseSigned,
  isSigningCaseRejected,
} from 'src/utils/SigningCase';

/*
  flex-basis is equal to Icon width to solve flex bug on IE11.

  In most cases where a max-size property is used on a flex item,
  the desired result is to have that item's initial size start at
  the value of the flex-basis property and grow to no larger than
  its max-size value.
*/
export const GridIcon = styled(GridStyled)`
  flex-basis: 40px !important;
`;

interface IRowProps {
  isRead: boolean;
  status: ISigningCase['status'];
}

type RowStyle = (props: IRowProps) => string;

const getTableRowBackgroundColor: RowStyle = ({ isRead }) =>
  isRead ? '#f8f8f8' : 'inherit';

const getBackgroundHoverColor: RowStyle = ({ isRead }) =>
  isRead ? '#EFF0F1' : transparentBlue;

type GetRowColor = (props: IRowProps) => string;
export const getRowColor: GetRowColor = ({ isRead, status }) =>
  isSigningCaseSigned(status)
    ? darkGreen
    : isSigningCaseRejected(status)
    ? darkRed
    : isRead
    ? darkBlue
    : grey;

export const GridRow = styled(({ isRead, status, ...props }: IRowProps) => (
  <Grid {...props} />
))<IRowProps & GridProps>`
  &:first-child {
    border-left: 8px solid ${getRowColor};
    border-right: 8px solid transparent;
  }

  background-color: ${getTableRowBackgroundColor};
  padding: 8px 0;

  &:hover {
    background-color: ${getBackgroundHoverColor};
  }
`;
