import React, { FC } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import ArrowDownWhite from 'src/assets/images/icons/arrow-link-down-expand-small_white.svg';

const ExpandMore: FC<WithNamespaces> = ({ t }) => {
  return <img src={ArrowDownWhite} alt={t('ArrowDownAltText')} />;
};

const ExtendedComponent = withNamespaces()(ExpandMore);
export { ExtendedComponent as ExpandMore };
