import styled from 'styled-components';

interface IfameType {
  isIframeLoaded: boolean;
}

export const Iframe = styled.iframe<IfameType>`
  width: 100%;
  visibility: ${props => (props.isIframeLoaded ? 'visible' : 'hidden')};
`;
