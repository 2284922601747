import { flowRight } from 'lodash';
import React, { FC } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { isSigningCaseArchived } from 'src/utils/SigningCase';
import { ITag } from '../../models';
import { DateText, TagText } from '../../Tag.styles';

const Date: FC<ITag & WithNamespaces> = ({ className, date, status, t }) => (
  <TagText className={className}>
    <span>
      {flowRight(
        t,
        getTagText
      )(status)}
    </span>
    <DateText>{date}</DateText>
  </TagText>
);

const getTagText = (status: ITag['status']) =>
  isSigningCaseArchived(status) ? 'DateArchived' : 'Expires';

const ExtendedComponent = withNamespaces()(Date);
export { ExtendedComponent as Date };
